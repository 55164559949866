import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Typography, Accordion, AccordionSummary, useTheme } from '@material-ui/core'

import Sanitizer from 'assets/Sanitizer'
import { useStyles } from './ExpandingText_style'
import IconFactory from 'icons/IconFactory/IconFactory'

const ExpandingText = ({ text }) => {
    const classes = useStyles()
    const [accordionIsExpanded, setAccordionIsExpanded] = useState(false)
    const [textDescription, setTextDescription] = useState({
        firstPart: null,
        secondPart: null
    })
    const theme = useTheme()

    const splitText = () => {
        const description = text
        const initialRenderedDescriptionMaxLength = 185

        if (!description) return { firstPart: null, secondPart: null }

        if (description.length <= initialRenderedDescriptionMaxLength) return { firstPart: description, secondPart: null }

        const splitIndex = description.lastIndexOf(' ', initialRenderedDescriptionMaxLength)
        return {
            firstPart: description.substring(0, splitIndex),
            secondPart: description.substring(splitIndex)
        }
    }

    useEffect(() => {
        const splittedText = splitText()
        setTextDescription(splittedText)
    }, [])

    return (<>
        {textDescription.firstPart && <Box className={classes.descriptionContainer}>
            <Typography component='p' className={classes.description}
                dangerouslySetInnerHTML={{ __html: Sanitizer.SanitizeHtmlString(`${textDescription.firstPart}${accordionIsExpanded ? textDescription.secondPart : ''}`) }} />
            {textDescription.secondPart && <Accordion onChange={() => setAccordionIsExpanded(!accordionIsExpanded)} defaultExpanded={false} elevation={0} className={classes.accordion}>
                <AccordionSummary classes={{ root: classes.accordionSummary, content: classes.accordionSummaryContent, expandIcon: classes.accordionSummaryIconButton }} expandIcon={<IconFactory icon='more' color={theme.palette.fg.black} />}>
                    <Typography variant='h2' className={classes.accordionSummaryTitle}>
                        <FormattedMessage id={accordionIsExpanded ? 'job_show.company_info_card.accordion_placeholder_secondary' : 'job_show.company_info_card.accordion_placeholder' }/>
                    </Typography>
                </AccordionSummary>
            </Accordion>}
        </Box>}
    </>)
}

export default ExpandingText
