/* eslint-disable react/prop-types */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Image, Transformation } from 'cloudinary-react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useStyles } from '../AboutFirstJobs/aboutabout_style'
import AboutUsHelmet from 'helmets/AboutUsHelmet'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import RouteMap from 'assets/RouteMap'
import { MEDIUM_SIZE } from 'assets/CloudinaryURLHelper'
import RecommendedJobsView from 'components/RecommendedJobsView/RecommendedJobsView'

const AboutUs = (props) => {
    const { lastOffers, user } = props
    const classes = useStyles()

    const teamsInfo = [
        { name: 'Florence Loumaye', role: 'Co-founder', image: 'florence-loumaye-co-founder-color' },
        { name: 'Matthieu Verstraete', role: 'Co-founder', image: 'matthieu-verstraete-co-founder-color' },
        { name: 'Ben Vanparys', role: 'Associate Partner & Business Development Manager', image: 'ben-vanparys-associate-partner-color' },
        { name: 'Héloïse Loumaye', role: 'Business Development Manager', image: 'heloise-circle' },
        { name: 'Edouard De Prez', role: 'Full Stack Developer', image: 'edouard-circle' },
        { name: 'Margaux Helsmoortel', role: 'Digital Marketing & Digital Campaign Manager', image: 'margaux-circle-2' },
        { name: 'Maximilien Romain', role: 'Full Stack Developer', image: 'maximilien-romain-2' },
        { name: 'Christophe Gillis', role: 'Full Stack Developer', image: 'chris-shoot' },
        { name: 'Klara Vansteeland', role: 'Digital Campaign Manager', image: 'klara-circle' },
        { name: 'Lisa Van Hoogenbemt', role: 'Recruitment & Selection Manager', image: 'lisa-shoot.jpg' },
        { name: 'Merel Vioen', role: 'Digital Campaign Manager', image: 'merel-shoot.jpg' },
        { name: 'Camille Polet', role: 'Digital Campaign Manager', image: 'camille-shoot.jpg' },
        { name: 'Colienne de Lovinfosse', role: 'Digital Campaign Manager', image: 'colienne.jpg' }
    ]

    const renderContentFromLanguage = () => {
        if (RouteMap.GetLocaleFromUrl() === 'fr') {
            return (
                <Container maxWidth='md' className={classes.aboutPageBox}>
                    <Typography variant='h2' className={classes.helloFuture}>
                        <FormattedMessage id='landing_student.hello_future' /> <FormattedMessage id='landing_student.lets_talk' />
                    </Typography>
                    <Box>
                        <Typography variant='h2' className={classes.subtitle}>
                            <FormattedMessage id='about_us_page.our_mission'/>
                        </Typography>
                        <Typography className={classes.ourMissionText}>
                            <FormattedMessage id='about_us_page.our_mission_description' />
                        </Typography>
                        <Typography variant='h2' className={classes.subtitle}>
                            <FormattedMessage id='about_us_page.community_strenght'/>
                        </Typography>
                        <Typography className={classes.text}>
                            <FormattedMessage id='about_us_page.community_strenght_description' />
                        </Typography>
                        <Typography variant='h2' className={classes.subtitle}>
                            <FormattedMessage id='about_us_page.we_believe'/>
                        </Typography>
                        <Typography variant='h3' className={classes.subsubtitle}>
                            <FormattedMessage id='about_us_page.no_bs'/>
                        </Typography>
                        <Typography className={classes.text}>
                            <FormattedMessage id='about_us_page.we_believe_description' />
                        </Typography>

                        <Typography variant='h3' className={classes.subsubtitle}>
                            <FormattedMessage id='about_us_page.innovation'/>
                        </Typography>
                        <Typography className={classes.text}>
                            <FormattedMessage id='about_us_page.innovation_description' />
                        </Typography>
                        <Typography variant='h3' className={classes.subsubtitle}>
                            <FormattedMessage id='about_us_page.going_further'/>
                        </Typography>
                        <Typography className={classes.text}>
                            <FormattedMessage id='about_us_page.going_further_description' />
                        </Typography>
                    </Box>
                </Container>
            )
        } else {
            return (<>
                <Container maxWidth='md' className={classes.aboutPageBox}>
                    <Box>
                        <Typography variant='h2' className={classes.subtitle}>
                            <FormattedMessage id='about_us_page.intro'/>
                        </Typography>
                        <Typography className={classes.text}>
                            <FormattedMessage id='about_us_page.description' />
                        </Typography>
                        <Typography variant='h2' className={classes.subtitle}>
                            <FormattedMessage id='about_us_page.our_reason_for_being.title'/>
                        </Typography>
                        <Box className={classes.breaklines}>
                            <Typography className={classes.text}>
                                <span className={classes.studentFont}>
                                    <FormattedMessage id='about_us_page.our_reason_for_being.point_1.title'/>
                                </span>
                                <FormattedMessage id='about_us_page.our_reason_for_being.point_1.description'/>
                            </Typography>
                            <Typography className={classes.text}>
                                <span className={classes.studentFont}>
                                    <FormattedMessage id='about_us_page.our_reason_for_being.point_2.title'/>
                                </span>
                                <FormattedMessage id='about_us_page.our_reason_for_being.point_2.description'/>
                            </Typography>
                            <Typography className={classes.text}>
                                <span className={classes.studentFont}>
                                    <FormattedMessage id='about_us_page.our_reason_for_being.point_3.title'/>
                                </span>
                                <FormattedMessage id='about_us_page.our_reason_for_being.point_3.description'/>
                            </Typography>
                            <Typography className={classes.text}>
                                <span className={classes.studentFont}>
                                    <FormattedMessage id='about_us_page.our_reason_for_being.point_4.title'/>
                                </span>
                                <FormattedMessage id='about_us_page.our_reason_for_being.point_4.description'/>
                            </Typography>
                            <Box>
                                <Typography className={classes.noMargin}>
                                    <span className={classes.studentFont}>
                                        <FormattedMessage id='about_us_page.our_reason_for_being.point_5.title'/>
                                    </span>
                                    <FormattedMessage id='about_us_page.our_reason_for_being.point_5.description'/>
                                </Typography>
                                <Box className={`${classes.listContainer} ${classes.listContainerMargin}`}>
                                    <Typography className={classes.text}>
                                        <FormattedMessage id='about_us_page.our_reason_for_being.point_5.list.content_1'/>
                                    </Typography>
                                    <Typography className={classes.text}>
                                        <FormattedMessage id='about_us_page.our_reason_for_being.point_5.list.content_2'/>
                                    </Typography>
                                    <Typography className={classes.text}>
                                        <FormattedMessage id='about_us_page.our_reason_for_being.point_5.list.content_3'/>
                                    </Typography>
                                    <Typography className={classes.text}>
                                        <FormattedMessage id='about_us_page.our_reason_for_being.point_5.list.content_4'/>
                                    </Typography>
                                </Box>
                            </Box>
                            <Typography className={classes.text}>
                                <span className={classes.studentFont}>
                                    <FormattedMessage id='about_us_page.our_reason_for_being.point_6.title'/>
                                </span>
                                <FormattedMessage id='about_us_page.our_reason_for_being.point_6.description'/>
                            </Typography>
                        </Box>
                    </Box>
                </Container>
                <Container maxWidth='md'>
                    <Box>
                        <Typography variant='h2' className={classes.subtitle}><FormattedMessage id='about_us_page.our_culture.title'/></Typography>
                        <Box mb={2} className={classes.sloganAuthor}>
                            <Typography className={classes.text}><FormattedMessage id='about_us_page.our_culture.slogan.title'/></Typography>
                            <Typography className={classes.text}><FormattedMessage id='about_us_page.our_culture.slogan.author'/></Typography>
                        </Box>
                        <Box className={classes.breaklines}>
                            <Typography className={classes.text}>
                                <Typography className={classes.studentFont}><FormattedMessage id='about_us_page.our_culture.point_1.title'/></Typography>
                                <FormattedMessage id='about_us_page.our_culture.point_1.description'/>
                            </Typography>
                            <Typography className={classes.text}>
                                <Typography className={classes.studentFont}><FormattedMessage id='about_us_page.our_culture.point_2.title'/></Typography>
                                <FormattedMessage id='about_us_page.our_culture.point_2.description'/>
                            </Typography>
                            <Typography className={classes.text}>
                                <Typography className={classes.studentFont}><FormattedMessage id='about_us_page.our_culture.point_3.title'/></Typography>
                                <FormattedMessage id='about_us_page.our_culture.point_3.description'/>
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </>)
        }
    }

    return (
        <PageWrapper user={user}>
            <AboutUsHelmet />
            <Box className={classes.aboutPageBanner}>
                <Container maxWidth='md'>
                    <Typography variant='h1' className={classes.titlePage}>
                        <FormattedMessage id='about_us_page.title' />
                    </Typography>
                </Container>
            </Box>
            {renderContentFromLanguage()}
            <Box mt={5} py={7} className={classes.teamContainer}>
                <Container maxWidth='md'>
                    <Typography variant='h2' className={classes.slogan}>
                        <FormattedMessage id='about_us_page.slogan' />
                    </Typography>
                    <Typography variant='h2' className={`${classes.slogan} ${classes.spacingTop}`}>
                        <FormattedMessage id='about_us_page.studentbe_team' />
                    </Typography>
                    <Grid container justify='center' spacing={2}>
                        {teamsInfo.map((item, index) => (
                            <Grid key={`team-box-${index}`} item xs={6} md={4} className={classes.teamBox}>
                                <Image
                                    cloudName='studentbe'
                                    publicId={`student-be-team/${item.image}`}
                                    alt={`student.be team ${item.name}`}
                                    width='220px'
                                    height='220px'
                                >
                                    <Transformation flags='progressive' fetchFormat='auto' width={MEDIUM_SIZE} quality='auto:eco' crop='fill' />
                                </Image>
                                <Typography className={classes.teamMemberName}>{item.name}</Typography>
                                {item.role.indexOf('&') !== -1
                                    ? <Typography className={classes.teamRole}>{item.role.split(' & ')[0]} &<br />{item.role.split(' & ')[1]}</Typography>
                                    : <Typography className={classes.teamRole}>{item.role}</Typography>
                                }
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
            <RecommendedJobsView
                items={lastOffers}
                title={<FormattedMessage id='job_show.recommended_jobs.title' />}
                moreItemsText={<FormattedMessage id='job_show.recommended_jobs.more_link' />}
                moreItemsURL={RouteMap.Index('student_job')}
            />
        </PageWrapper>
    )
}

export default AboutUs
