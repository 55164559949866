import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    boxTitle: {
        display: 'flex',
        alignItems: 'center'
    },
    avatarStyle: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: '1px solid' + theme.palette.primary.main,
        fontFamily: 'GreedWide-Bold',
        marginRight: 15,
        width: 30,
        height: 30,
        fontSize: 30,
        [theme.breakpoints.down('sm')]: {
            marginRight: 4,
            fontSize: 16
        }
    },
    typoStyle: {
        fontFamily: 'GreedWide-Bold',
        margin: '0 !important',
        display: 'flex',
        alignItems: 'center',
        fontSize: 30,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        }
    }
}))
