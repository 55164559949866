import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    bannerContainer: {
        width: '100%',
        zIndex: 1,
        position: 'relative',
        minHeight: (props) => props.editPage ? 200 : 0,
        backgroundColor: (props) => props.editPage ? 'var(--background-purple)' : '',
        lineHeight: (props) => props.editPage ? 0 : 'inherit',
        '& img': {
            width: '100%',
            objectFit: 'cover',
            maxHeight: 400,
            minHeight: 200,
        }
    },
    shareButton: {
        position: 'absolute',
        top: 20,
        right: 20,
        cursor: 'pointer',
        backgroundColor: 'white',
        borderRadius: 4
    },
    addBannerCTA: {
        color: 'white',
        fontSize: 22,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    pageContent: {
        width: '100%'
    },
    companyInfo: {
        display: 'flex',
        flexDirection: 'row',
        gap: 30,
        marginBottom: 32,
        [theme.breakpoints.down('sm')]: {
            gap: 20,
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: 20,
        }
    },
    logo: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    logoBox: {
        overflow: 'hidden',
        position: 'relative',
        zIndex: 1,
        marginTop: (props) => props.noBanner ? 12 : -35,
        display: 'block',
        width: 150,
        minWidth: 150,
        height: 150,
        background: 'var(--background-white)',
        boxShadow: 'var(--box-shadow)',
        border: 'var(--border)',
        borderRadius: '50%',
        [theme.breakpoints.down('sm')]: {
            marginTop: (props) => props.noBanner ? 12 : -70,
            width: 120,
            minWidth: 120,
            height: 120,
        }
    },
    companyInfoText: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center'
        }
    },
    companyNameAndCTA: {
        display: 'flex',
        gap: 12,
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse'
        }
    },
    editPageButton: {
        minWidth: 'fit-content',
    },
    companyName: {
        fontFamily: 'GreedWide-Bold',
        fontSize: 30,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    companyDetailsFlex: {
        display: 'flex',
        gap: 20,
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            justifyContent: 'center'
        }
    },
    companyDetails: {
        display: 'flex',
        gap: 4,
        alignItems: 'center'
    },
    companyBodyContainer: {
        display: 'flex',
        width: '100%',
        marginBottom: 40,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse'
        }
    },
    companyBodyLeftSide: {
        width: '25%',
        height: 'fit-content',
        marginRight: 20,
        overflowWrap: 'break-word',
        [theme.breakpoints.down('md')]: {
            width: '33%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            border: '1px solid var(--foreground-silver)',
            borderRadius: 'var(--border-radius)',
            padding: 12,
            margin: '20px 0'
        }
    },
    companyTitle: {
        marginBottom: 20,
        color: 'var(--foreground-black)',
        fontSize: 24,
    },
    companyDescription: {
        color: 'var(--foreground-black)',
        fontSize: 16,
        '& a': {
            color: 'var(--background-black)',
            textDecoration: 'underline',
            '&:hover': {
                color: 'var(--background-purple)'
            }
        }
    },
    companyArticlesContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        marginTop: 40
    },
    companyArticlesTitle: {
        color: 'var(--foreground-black)',
        fontSize: 20
    },
    companyBodyRightSide: {
        display: 'flex',
        flexDirection: 'column',
        width: '75%',
        marginLeft: 10,
        [theme.breakpoints.down('md')]: {
            width: '66%',
            marginLeft: 0
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    companyBodyOverview: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    companyBodyOverviewCenter: {
        width: '70%',
        [theme.breakpoints.down('md')]: {
            width: '50%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    videoBox: {
        '& iframe': {
            border: 'var(--border)',
            borderRadius: 20,
            boxShadow: 'var(--box-shadow)'
        }
    },
    companyBodyOverviewSide: {
        width: '30%',
        marginLeft: 20,
        [theme.breakpoints.down('md')]: {
            width: '50%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: '20px auto'
        }
    },
    companyBodyOverviewImages: {
        width: '100%',
        marginTop: 40
    },
}))
