import React from 'react'

import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import AttentionBanner from 'components/AttentionBanner/AttentionBanner'
import ProgressCircle from 'components/ProgressCircle/ProgressCircle'
import SharedTabs from 'components/SharedTabs/SharedTabs'
import CompanyJobCard from 'cards/CompanyJobCard/CompanyJobCard'
import SortByDropdown from 'inputs/SortByDropdown/SortByDropdown'
import Button from 'components/Button/Button'
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox'
import DeleteCompanyJobsModal from 'modals/DeleteCompanyJobsModal/DeleteCompanyJobsModal'
import ActivateCompanyJobModal from 'modals/ActivateCompanyJobModal/ActivateCompanyJobModal'
import BoostCompanyJobModal from 'modals/BoostCompanyJobModal/BoostCompanyJobModal'
import FeedbackSnackbar from 'components/FeedbackSnackbar/FeedbackSnackbar'
import EmployerJobIndexSearchBar from './EmployerJobIndexSearchBar/EmployerJobIndexSearchBar'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Pagination from '@material-ui/lab/Pagination'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'

import { useStyles } from './EmployerJobIndexView_style'
import { FormattedMessage } from 'react-intl'

const EmployerJobIndexView = (props) => {
    const { user, company, loading, items, page, setPage, itemCount, feedback, setFeedback, getCompanyJobs, pageCount, orderKey, orderDirection, setSortBy, removeCompanyJob, toggleActive, toggleBoost, checkAll, checkAllJobs, handleJobCheck, checkedItems, removeJobsFromItems, showDeleteCompanyJobsModal, setShowDeleteCompanyJobsModal, showActivateCompanyJobModal, setShowActivateCompanyJobModal, jobToActivate, toggleActiveJobStatus, creditCount, setCreditCount, showBoostCompanyJobModal, setShowBoostCompanyJobModal, jobToBoost, filters, setFilters } = props
    const classes = useStyles()

    const ORDER_KEYS = [
        [<FormattedMessage key='title' id='users_index_views.company_jobs.sort.title'/>, 'title'],
        [<FormattedMessage key='active' id='users_index_views.company_jobs.sort.status'/>, 'active'],
        [<FormattedMessage key='applied_date' id='users_index_views.company_jobs.candidacy_date'/>, 'applied_date']
    ]

    const closeFeedback = () => setFeedback({ ...feedback, open: false })
    const handleChangePage = (event, newPage) => setPage(newPage)

    const renderCompanyJobCards = () => {
        const CompanyJobCards = items.map((companyJob) => {
            return (
                <Grid item my={1} xs={12} key={'company-job-' + companyJob.type + '-' + companyJob.id}>
                    <CompanyJobCard
                        job={companyJob}
                        remove={removeCompanyJob}
                        toggleBoost={toggleBoost}
                        showHeadhunt={user.show_headhunt}
                        user={user}
                        handleJobCheck={handleJobCheck}
                        checked={checkedItems.includes(companyJob)}
                        removeCompanyJob={removeCompanyJob}
                        showActivateCompanyJobModal={showActivateCompanyJobModal}
                        toggleActive={toggleActive}
                    />
                </Grid>
            )
        })
        return CompanyJobCards
    }

    return (
        <PageWrapper user={user} disableFooterLists>
            <SharedTabs user={user} currentTab='users/company_jobs' />
            <AttentionBanner user={user} />
            <DeleteCompanyJobsModal
                showModal={showDeleteCompanyJobsModal}
                setShowModal={setShowDeleteCompanyJobsModal}
                setFeedback={setFeedback}
                checkedJobsLength={checkedItems.length}
                checkedItems={checkedItems}
                removeJobsFromItems={removeJobsFromItems}
            />
            <ActivateCompanyJobModal
                showModal={showActivateCompanyJobModal}
                setShowModal={setShowActivateCompanyJobModal}
                setFeedback={setFeedback}
                jobToActivate={jobToActivate}
                toggleActiveJobStatus={toggleActiveJobStatus}
                creditCount={creditCount}
                setCreditCount={setCreditCount}
                user={user}
                company={company}
                filters={filters}
            />
            <BoostCompanyJobModal
                showModal={showBoostCompanyJobModal}
                setShowModal={setShowBoostCompanyJobModal}
                setFeedback={setFeedback}
                jobToBoost={jobToBoost}
                creditCount={creditCount}
                setCreditCount={setCreditCount}
                toggleActiveJobStatus={toggleActiveJobStatus}
                user={user}
                company={company}
                filters={filters}
            />
            <FeedbackSnackbar
                open={feedback.open}
                message={feedback.message}
                severity={feedback.severity}
                closeFeedback={closeFeedback}
            />
            <Container maxWidth="lg" className={classes.container}>
                <EmployerJobIndexSearchBar
                    getCompanyJobs={getCompanyJobs}
                    filters={filters}
                    setFilters={setFilters}
                    searchParams={ORDER_KEYS}
                />
                <Box my={2}>
                    <Grid container>
                        <Hidden mdDown>
                            <Grid item xs={12} md={3} >
                                {items.length !== 0 &&
                                    <Box width='100%' height='100%' display='flex' alignItems='center' >
                                        <Box mr={2}>
                                            <CustomCheckbox
                                                color='primary'
                                                onChange={checkAllJobs}
                                                checked={checkAll}
                                            />
                                        </Box>
                                        <Typography variant='body2' color='primary'>
                                            <FormattedMessage id="item_index.select_all" />
                                        </Typography>
                                        {checkedItems.length > 0 &&
                                            <Box ml={2}>
                                                <Button
                                                    onClick={() => setShowDeleteCompanyJobsModal(true)}
                                                    variant='primary'
                                                    size='small'
                                                >
                                                    <FormattedMessage id="item_index.button.delete" />
                                                </Button>
                                            </Box>
                                        }
                                    </Box>
                                }
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} lg={6} spacing={3}>
                            <Box
                                width='100%'
                                display='flex'
                                alignItems='center'
                                justifyContent='center'
                                flexDirection='column'
                            >
                                {user.gold_licence &&
                                    <Typography variant='body2'>
                                        <FormattedMessage id="item_index.gold_licence"/>
                                    </Typography>
                                }
                                {!user.gold_licence && user.silver_licence && <>
                                    <Typography variant='body2'>
                                        <FormattedMessage id="item_index.silver_licence"/>
                                    </Typography>
                                    <Typography variant='body2'>
                                        <FormattedMessage
                                            id={`employer_job_index.credits_left_silver.${creditCount === 1 ? 'one' : 'other'}`}
                                            values={{ count: creditCount }}
                                        />
                                    </Typography>
                                </>}
                                {!user.gold_licence && !user.silver_licence &&
                                    <Typography variant='body2'>
                                        <FormattedMessage
                                            id={`employer_job_index.credits_left.${creditCount === 1 ? 'one' : 'other'}`}
                                            values={{ count: creditCount }}
                                        />
                                    </Typography>
                                }
                                {itemCount &&
                                    <Typography variant='body2'>
                                        <FormattedMessage
                                            id={`item_index.offer${items.length === 1 ? '' : 's'}_create`}
                                            values={{ offer_create: itemCount }}
                                        />
                                    </Typography>
                                }
                            </Box>
                        </Grid>
                        <Grid item s={12} md={3} spacing={3}>
                            <SortByDropdown
                                keys={ORDER_KEYS}
                                sortby={orderKey}
                                onClick={setSortBy}
                                order={orderDirection}
                            />
                        </Grid>
                    </Grid>
                </Box>
                {loading && <ProgressCircle />}
                {!loading && itemCount > 0 && <>
                    <Grid container spacing={1}>
                        {renderCompanyJobCards()}
                    </Grid>
                    <Box my={2} display='flex' justifyContent='center'>
                        <Pagination count={pageCount} page={page} onChange={handleChangePage} />
                    </Box>
                </>}
                {!loading && itemCount === 0 && <>
                    <Box my={2} className={classes.createJobNudgeContainer}>
                        <Box className={classes.createJobNudge}>
                            <Typography variant='h6'><FormattedMessage id='employer_job_index.no_jobs_created.text_1' values={{ first_name: user.first_name }} /></Typography>
                            <Typography variant='h6'><FormattedMessage id='employer_job_index.no_jobs_created.text_2' /></Typography>
                            <Typography variant='p'><FormattedMessage id='employer_job_index.no_jobs_created.text_3' /></Typography>
                        </Box>
                        <RouteMapLink page='employer/new-ad'>
                            <Button
                                variant='primary'
                                size='small'
                                className={classes.myAdsButton}
                            >
                                <FormattedMessage id='item_index.post_offer' />
                            </Button>
                        </RouteMapLink>
                    </Box>
                </>}
            </Container>
        </PageWrapper>
    )
}

export default EmployerJobIndexView
