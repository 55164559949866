import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    bannerContainer: {
        padding: '0px 30px 24px',
        [theme.breakpoints.up(1340)]: {
            paddingLeft: '0px',
            paddingRight: '0px'
        }
    },
    containerStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: '1px solid var(--foreground-silver)',
        borderRadius: 20,
        padding: 24,
        position: 'relative'
    },
    leftContainer: {
        width: '720px',
        display: 'flex',
        columnGap: '48px'
    },

    rightContainer: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 120,
        justifyContent: 'space-between',
        height: '100%'
    },

    iconButtonsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '15px',
        position: 'absolute',
        bottom: 24,
        right: 24
    },

    buttonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: '21px',
        [theme.breakpoints.up(1100)]: {
            flexDirection: 'row'
        },
        position: 'absolute',
        top: 24,
        right: 24
    },

    applyButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: '21px',
        [theme.breakpoints.up(1100)]: {
            flexDirection: 'row'
        }
    },

    companyLogoContainer: {

    },

    logoBox: {
        overflow: 'hidden',
        display: 'block',
        width: '102px',
        height: '102px',
        boxShadow: `4px 4px 0px 0px ${theme.palette.fg.black}`,
        border: `2px solid ${theme.palette.fg.black}`,
        borderRadius: '50%'
    },

    logo: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },

    textContainer: {

    },

    companyName: {
        paddingBottom: '17px',
        color: theme.palette.fg.black,
        fontSize: '16px',
        lineHeight: '20px'
    },

    jobTitle: {
        marginBottom: '16px',
        color: theme.palette.fg.black,
        fontFamily: 'GreedWide-Bold',
        fontSize: '40px',
        lineHeight: '50px',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        WebkitLineClamp: 3,
        textOverflow: 'ellipsis'
    },

    tagsContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '13px'
    },

    tag: {
        height: 'fit-content',
        borderRadius: '59px',
        backgroundColor: theme.palette.bg.greyPale
    },

    tagLabel: {
        padding: '4px 30px',
        fontFamily: 'GreedStandard-Medium',
        fontSize: '12px',
        lineHeight: '21px'
    }
}))
