import React, { useEffect, useState, useRef } from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './NewsCategoryIndex_style'
import { Box, Chip, Typography, Container, useTheme } from '@material-ui/core'

import ArticleAPI from 'api/ArticleAPI'
import CollectionHandler from 'assets/CollectionHandler.js'
import HomeSearch from 'components/HomeSearch/HomeSearch'
import NewsSearchBar from '../shared/NewsSearchBar/NewsSearchBar'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import SEOBlock from 'pages/ItemIndexMain/SEOBlock/SEOBlock'
import FiltersViewWrapper from 'shared/components/FiltersViewWrapper/FiltersViewWrapper.jsx'
import ArticlesList from '../shared/ArticlesList/ArticlesList'

const NewsCategoryIndex = (props) => {
    const classes = useStyles()
    const theme = useTheme()
    const [categoryArticles, setCategoryArticles] = useState([])
    const [categoryArticlesCount, setCategoryArticlesCount] = useState(null)
    const [indexPage, setIndexPage] = useState(1)
    const [isInView, setIsInView] = useState(false)
    const loadMoreRef = useRef(null)

    let newsSubCategories = CollectionHandler.Get('newsIndexSubCategories')[props.selectedArticleTagObject.value]?.sub_categories || []
    if (newsSubCategories.length === 0) { newsSubCategories = CollectionHandler.Get('newsIndexSubCategoriesStuyDomains') }

    const getCurrentCategoryTranslated = () => {
        const category = props.selectedArticleTagObject.value
        const translatedCategory = CollectionHandler.Translate('articleCategoryCollection', category)

        // If translation is set as '/', that means the category as to be a study domain
        if (translatedCategory === '/') {
            return CollectionHandler.Translate('studyDomainCollection', category)
        } else {
            return translatedCategory
        }
    }
    const currentCategoryTranslated = getCurrentCategoryTranslated()

    useEffect(() => {
        if (isInView) {
            if (categoryArticlesCount > categoryArticles.length) setIndexPage(indexPage + 1)
        }
    }, [isInView])

    useEffect(() => {
        const handleScroll = () => {
            const element = loadMoreRef.current

            if (element) {
                const rect = element.getBoundingClientRect()
                const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0

                setIsInView(isVisible)
            }
        }

        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        loadCategoryArticles()
    }, [indexPage])

    const loadCategoryArticles = () => {
        ArticleAPI.filterArticle({
            categories: [props.selectedArticleTagObject.value],
            page: indexPage,
            load_previous_pages: true
        }).then((response) => {
            setCategoryArticles(response.data.res)
            setCategoryArticlesCount(response.data.search_count)
        })
    }

    const renderCategoryChip = (category) => {
        const categoryTranslated = CollectionHandler.Translate('articleTagCollection', category.name)

        return (
            <RouteMapLink articleCategory={category.name}>
                <Chip
                    label={categoryTranslated}
                    classes={{ root: classes.chipRoot, label: classes.chipLabel }}
                />
            </RouteMapLink>
        )
    }

    return (
        <Box className={classes.pageContainer}>
            <FiltersViewWrapper title={currentCategoryTranslated}>
                <Box className={classes.filterViewContent}>
                    <Container maxWidth='lg' className={classes.breadcrumbsContainer}>
                        <Typography variant='h2' component='div' className={classes.breadcrumbs}>
                            <RouteMapLink page='student-life'><span color={theme.palette.fg.black}>News /</span></RouteMapLink>
                            {' '}{currentCategoryTranslated}
                        </Typography>
                    </Container>
                    <NewsSearchBar />
                </Box>
            </FiltersViewWrapper>
            <Container maxWidth='lg' className={classes.quickFiltersContainer} disableGutters>
                <Typography className={classes.quickFiltersTitle} variant='h4'>
                    <FormattedMessage id='news_index.quick_filters_title' />
                </Typography>
                {newsSubCategories.map((category) => renderCategoryChip(category))}
            </Container>
            <Container maxWidth='lg' className={classes.pageContent} disableGutters>
                <Box className={classes.articlesContainer}>
                    <FormattedMessage id={`blog_section.category_index.articles_count${categoryArticlesCount < 2 ? '_singular' : ''}`} values={{ articlesCount: categoryArticlesCount, category: currentCategoryTranslated.toLowerCase() }}>
                        {text =>
                            <Typography variant='h1' component='h2' className={classes.articlesContainerTitle}
                                dangerouslySetInnerHTML={{ __html: text }}
                            />
                        }
                    </FormattedMessage>
                    <ArticlesList articles={categoryArticles} />
                </Box>
                <HomeSearch />
            </Container>
            <SEOBlock
                type='article'
                selectedLocationTag={props.selectedArticleTagObject}
            />
            <Box ref={loadMoreRef} />
        </Box>
    )
}

export default NewsCategoryIndex
