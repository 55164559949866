import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'

import MessageDisplay from './MessageDisplay'
import MessagesEmojiPicker from '../MessagesEmojiPicker/MessagesEmojiPicker'
import RouteMap from 'assets/RouteMap'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import ConversationAPI from 'api/ConversationAPI'
import IconFactory from 'icons/IconFactory/IconFactory'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import CvButton from 'buttons/CvButton/CvButton'
import ProgressCircle from 'components/ProgressCircle/ProgressCircle'
import Button from 'components/Button/Button'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

class MessagesDiscussion extends React.Component {
    constructor (props) {
        super(props)
        this.speakMessage = this.speakMessage.bind(this)
        this.updateMessages = this.updateMessages.bind(this)
        this.openDiscussion = this.openDiscussion.bind(this)
        this.closeDiscussion = this.closeDiscussion.bind(this)
        this.addFile = this.addFile.bind(this)
        this.addEmoji = this.addEmoji.bind(this)
        this.createAndAddFile = this.createAndAddFile.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.sendMsg = this.sendMsg.bind(this)
        this.jobResultLink = this.jobResultLink.bind(this)
        this.getInputText = this.getInputText.bind(this)
        this.highlightConversationCard = this.highlightConversationCard.bind(this)

        this.state = {
            isChatting: 'notChatting',
            messages: [],
            chatType: props.chatType ? props.chatType : '',
            talkingWith: props.talkingWith,
            userId: '',
            candidacy: null,
            candidacyCvUrl: '',
            addFile: false,
            loading: props.chatContext !== 'ChatWindow',
            loadingMessage: false,
            itemUrl: '',
            messageText: '',
            conversationId: this.initConversationId(),
            candidacyType: this.getCandidacyType(),
            chatTitle: props.chatTitle ? props.chatType : '',
            initializeChat: true,
            chatUrl: props.job?.chat_url
        }
    }

    componentDidUpdate (prevProps) {
        if (this.props.chatContext === 'ChatWindow') {
            this.props.conversationId && this.openDiscussion(this.props.conversationId)
        } else if (this.props.match.params.conversationid !== prevProps.match.params.conversationid) {
            this.openDiscussion(this.props.match.params.conversationid)
        }
    }

    componentDidMount () {
        if (this.state.conversationId) {
            this.openDiscussion(this.state.conversationId)
        }
    }

    initConversationId () {
        if (this.props.chatContext === 'ChatWindow') return this.props.conversationId

        return this.props.match.params.conversationid
    }

    getCandidacyType () {
        if (this.props.chatContext === 'ChatWindow') return this.props.candidacyType

        return this.props.match.params.candiType
    }

    jobResultLink () {
        return `${RouteMap.Page('/')}users/job_results?jobId=${this.state.candidacy[(this.state.chatType + '_id')]}&jobType=${this.state.chatType}`
    }

    handleChange (event) {
        const { name, value } = event.target
        this.setState({ ...this.state, [name]: value })
    }

    addFile (userinput) {
        this.setState({
            addFile: userinput
        })
    }

    addEmoji (emoji) {
        this.setState({ messageText: this.state.messageText + ' ' + emoji })
    }

    createAndAddFile (doc_id, format, name) {
        ConversationAPI.AddFileToMessage({
            conv_id: this.state.conversationId,
            img_id: doc_id,
            recipient_id: this.state.talkingWith.user_id
        }).then(response => {
            this.updateMessages()
            this.addFile(false)
        })
    }

    closeDiscussion () {
        window.history.replaceState(null, '', RouteMap.Page('messages'))

        const messagesDOM = document.querySelectorAll('.msg-container ')
        messagesDOM.forEach((message) => {
            message.style.transition = 'none'
        })
        if (window.innerWidth <= 1140) {
            document.querySelector('.messages-index-container').style.display = 'block'
            document.querySelector('.messages-discussion-and-apllications').style.display = 'none'
        }
        var cardSelection = document.querySelector(`.chat_card_${this.state.conversationId} .message-choice-card`)
        if (cardSelection) {
            cardSelection.classList.remove('active')
        }
    }

    highlightConversationCard (conversationToOpenId) {
        var cardSelection = document.querySelector(`.chat_card_${conversationToOpenId} .message-choice-card`)
        if (cardSelection) {
            cardSelection.classList.add('active')
        }
    }

    openDiscussion (conversationToOpenId) {
        const { formatMessage } = this.props.intl
        const messagesDOM = document.querySelectorAll('.msg-container ')
        messagesDOM.forEach((message) => {
            message.style.transition = 'none'
        })
        if (window.innerWidth <= 1140 && this.props.chatContext !== 'ChatWindow') {
            document.querySelector('.messages-index-container').style.display = 'none'
            document.querySelector('.messages-discussion-and-apllications').style.display = 'block'
        }
        var chat = this
        var card = document.querySelector(`.chat_card_${conversationToOpenId} .kot-message-notification`)
        if (card) {
            card.style.display = 'none'
        }

        document.querySelectorAll('.message-choice-card').forEach(function (card) {
            card.classList.remove('active')
        })
        if (this.state.initializeChat || (this.state.loading === false && conversationToOpenId !== this.state.conversationId)) {
            this.setState({ loading: true, initializeChat: false })

            ConversationAPI.GetMessages({
                conv_id: conversationToOpenId
            }).then(response => {
                if (response.data.error) {
                    window.location.href = RouteMap.Page('/')
                }
                if (this.state.candidacyType === 'kot' &&
                    (this.props.user.is_student_kot_owner || this.props.user.type === 'kot_owner') &&
                    this.props.user.id != response.data.candidacy.user_id
                ) {
                    this.props.openItem(
                        this.state.candidacyType,
                        response.data.item_id,
                        response.data.item_title,
                        0,
                        () => this.highlightConversationCard(conversationToOpenId)
                    )
                }
                this.setState({
                    isChatting: 'Chatting',
                    messages: response.data.messages,
                    candidacy: response.data.candidacy,
                    conversationId: conversationToOpenId,
                    chatType: this.state.candidacyType,
                    talkingWith: response.data.recipient_user,
                    chatTitle: response.data.item_title,
                    userId: response.data.myUser,
                    candidacyCvUrl: response.data.candidacy_cv_url,
                    loading: false,
                    itemUrl: response.data.item_url,
                    itemId: response.data.item_id,
                    messageText: this.getInputText(response.data.messages)
                }, () => {
                    this.highlightConversationCard(conversationToOpenId)
                })
                const messagesDOM = document.querySelectorAll('.msg-container ')
                messagesDOM.forEach((message, i) => {
                    setTimeout(() => {
                        message.classList.add('show-message')
                    }, i * 100)
                })
                const that = this
                App.cable.subscriptions.create({
                    channel: 'ConversationsChannel',
                    conversation_id: this.state.conversationId
                }, {
                    connected: function () {
                        const messageInput = document.getElementById('message_input')
                        const ChatBox = messageInput && messageInput.children[1]
                        // ChatBox.removeEventListener('keypress', function(){chat.sendMessage(event, id)}, true)
                        var convId = that.state.conversationId
                        var messageList = document.querySelector('.messages-list-container')
                        // ChatBox.addEventListener('keypress', function () { chat.sendMessage(event) }, true)
                    },
                    disconnected: function () {},
                    received: function (data) {
                        that.updateMessages()
                    }
                })
            })
        }
    }

    getInputText (messages) {
        const { formatMessage } = this.props.intl
        if (messages === 0) {
            if (this.props.user.type === 'student') {
                return formatMessage({ id: 'job_chat.default_message' })
            } else {
                return 'apply_popup.apply_message_placeholder'
            }
        }

        return ''
    }

    updateMessages () {
        const { formatMessage } = this.props.intl
        if (this.state.conversationId && this.state.loading === false) {
            this.setState({ loading: true })
            ConversationAPI.GetMessages({
                conv_id: this.state.conversationId
            }).then(response => {
                if (response.data.error) {
                    if (false) { window.location.href = RouteMap.Page('/') }
                }
                this.setState({
                    isChatting: 'Chatting',
                    messages: response.data.messages,
                    candidacy: response.data.candidacy,
                    conversationId: this.state.conversationId,
                    chatType: this.state.candidacyType,
                    talkingWith: response.data.recipient_user,
                    chatTitle: this.state.chatTitle,
                    userId: response.data.myUser,
                    candidacyCvUrl: response.data.candidacy_cv_url,
                    loading: false,
                    loadingMessage: false,
                    itemUrl: response.data.item_url,
                    messageText: response.data.messages.length === 0 ? formatMessage({ id: 'job_chat.default_message' }) : ''
                })
                const messagesDOM = document.querySelectorAll('.msg-container ')
                messagesDOM.forEach((message) => {
                    message.style.transition = 'none'
                })
                messagesDOM.forEach((message, i) => {
                    setTimeout(() => {
                        message.classList.add('show-message')
                    }, i * 100)
                })
            })
        }
    }

    speakMessage () {
        if (this.state.messageText) {
            const message = {
                content: this.state.messageText,
                user_id: this.props.user.id,
                sender_id: this.props.user.id,
                conversation_id: this.state.conversationId,
                recipient_id: this.state.talkingWith.user_id
            }
            this.setState({
                loadingMessage: true
            }, () => {
                App.conversations.speak(message)
                // this.updateMessages()
                this.setState({ messageText: '' })
            })
        }
    }

    sendMsg () {
        if (!this.state.conversationId) {
            this.createConversation()
        } else {
            this.speakMessage()
        }
    }

    createConversation () {
        const that = this
        ConversationAPI.CreateConversation({
            job_type: this.state.candidacyType,
            job_id: this.props.job.id,
            chat_text: this.state.messageText
        }).then((response) => {
            AnalyticsHelper.sendGTMEvent('employer-chat-window-success')
            this.setState({
                conversationId: response.data.conversation_id,
                chatUrl: response.data.redirection_url
            }, () => {
                that.openDiscussion(response.data.conversation_id)
            })
        }).catch(function (error) {
            console.log('Error while  updating user location url: ' + error)
        })
    }

    render () {
        const { formatMessage } = this.props.intl
        const name = this.state.talkingWith && (
            this.state.talkingWith.name
                ? this.state.talkingWith.name
                : this.state.talkingWith.first_name
                    ? this.state.talkingWith.first_name + ' ' + this.state.talkingWith.last_name
                    : this.state.talkingWith.user_mail_abbreviated
        )
        return (
            <div className={`messages-discussion-main ${this.state.chatType.replace(' ', '_')} ${this.props.chatContext === 'ChatWindow' ? 'chat-window-context' : ''}`}>
                <div className={'messages-conversation-title-container'}>
                    {this.state.loading && <ProgressCircle disableMargin small />}
                    {!this.state.loading && <>
                        {this.props.chatContext !== 'ChatWindow' &&
                            <div className="backfromMobile">
                                <IconFactory icon='back' type='button' onClick={this.closeDiscussion} />
                            </div>
                        }
                        <div className="head-title-container-msg">
                            <Box className="companyName" display='flex' alignItems='center'>
                                <Box>
                                    {name}
                                    {this.props.user.type === 'student' && `: ${this.state.chatTitle}`}
                                </Box>
                                <Box mx={1} display='flex'>
                                    {this.props.user.type === 'employer' &&
                                        this.state.candidacy &&
                                        this.state.candidacyCvUrl &&
                                        !this.state.candidacy.chat_only &&
                                        <CvButton
                                            cvUrl={this.state.candidacyCvUrl}
                                            oldCvUrl={this.state.candidacy && this.state.candidacy.old_cv_url}
                                        />
                                    }
                                    {this.props.chatContext === 'ChatWindow' && this.state.chatUrl &&
                                        <IconButton
                                            style={{ minWidth: 'unset' }}
                                            href={RouteMap.Page(this.state.chatUrl)}
                                        >
                                            <IconFactory icon='expand' />
                                        </IconButton>
                                    }
                                    {this.props.chatContext !== 'ChatWindow' && <>
                                        <Box>
                                            {this.state.chatType &&
                                            <Tooltip title={<FormattedMessage id="company_job_card.tooltips.show_offer" />}>
                                                <IconButton
                                                    href={RouteMap.Show(this.state.chatType, this.state.itemUrl)}
                                                    target='_blank'
                                                    className='icon-button-message-discussion'
                                                >
                                                    <IconFactory padding='8px' icon='view' />
                                                </IconButton>
                                            </Tooltip>
                                            }
                                        </Box>
                                        <Box>
                                            {this.state.candidacy && this.state.chatType && this.props.user.type === 'employer' &&
                                            <Tooltip title={<FormattedMessage id="headhunt.banner.go_to_results" />}>
                                                <IconButton
                                                    href={this.jobResultLink()}
                                                    target='_blank'
                                                    className='icon-button-message-discussion'
                                                >
                                                    <IconFactory padding='8px' icon='candidacies' />
                                                </IconButton>
                                            </Tooltip>
                                            }
                                        </Box>
                                    </>}
                                </Box>
                            </Box>
                            <Hidden smDown>
                                {this.state.candidacy && this.props.user.type === 'employer' &&
                                    <Box mb={1} className="CompanyUser" display='flex' alignItems='center'>
                                        <FormattedMessage id='chat.job_title' values={{ job_title: this.state.chatTitle }}/>
                                    </Box>
                                }
                                {this.state.candidacy?.chat_only && this.props.user.type === 'employer' &&
                                    <Box mb={1} className="CompanyUser" display='flex' alignItems='center'>
                                        <Box>
                                            <FormattedMessage id='job_chat.employer_chat' />
                                        </Box>
                                    </Box>
                                }
                                {this.state.candidacy?.chat_only && this.props.user.type === 'student' &&
                                    <Box mb={1} className="CompanyUser" display='flex' alignItems='center'>
                                        <RouteMapLink showItemType={this.state.chatType} showItemUrl={this.state.itemUrl}>
                                            <Button variant='tertiary' size='small'>
                                                <FormattedMessage id="job_chat.apply_to_this_job" />
                                            </Button>
                                        </RouteMapLink>
                                    </Box>
                                }
                            </Hidden>
                        </div>
                    </>}
                </div>
                <div className={'messages-conversation-container'}>
                    <MessageDisplay
                        functionOnSuccess={this.createAndAddFile}
                        addFileFunction={this.addFile}
                        addFile={this.state.addFile}
                        user={this.props.user}
                        candidacy={this.state.candidacy}
                        chatType={this.state.chatType}
                        chatTitle={this.state.chatTitle}
                        itemUrl={this.state.itemUrl}
                        messages={this.state.messages}
                    />
                </div>
                <div className="messages-conversation-input-container">
                    <div id="create_message">
                        <div id="message_input" className="textareaContainer">
                            {this.props.chatContext !== 'ChatWindow' &&
                                <Box display='flex' flexDirection='column'>
                                    <Box py={1} display='flex' justifyContent='center' style={{ cursor: 'pointer' }}>
                                        <MessagesEmojiPicker addEmoji={this.addEmoji} />
                                    </Box>
                                    <Box className="addInput" onClick={() => this.addFile(true)}>
                                        <IconFactory
                                            icon='attachment'
                                            className="sendmsg"
                                            fontSize='2rem'
                                        />
                                    </Box>
                                </Box>
                            }
                            {this.state.loadingMessage
                                ? <Box width='100%' display='flex' justifyContent='center'>
                                    <ProgressCircle disableMargin small />
                                </Box>
                                : <textarea
                                    className="description_area"
                                    name="messageText"
                                    id="create"
                                    placeholder={formatMessage({ id: 'apply_popup.apply_message_placeholder' })}
                                    value={this.state.messageText}
                                    onChange={this.handleChange}
                                />
                            }
                            {!this.state.addFile &&
                                <div className="sendinput" onClick={() => this.sendMsg()}>
                                    <IconFactory
                                        icon='send'
                                        className="sendmsg"
                                        fontSize='2rem'
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(MessagesDiscussion)
