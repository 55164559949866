import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    companyJobCardContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            height: '100px'
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    },
    companyJobCard: {
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            height: '100%',
            display: 'flex'
        },
        [theme.breakpoints.down('md')]: {
            position: 'relative',
            padding: '1rem 1rem 1rem 1.5rem'
        }
    },
    companyJobCardColumn: {
        display: 'flex',
        [theme.breakpoints.up('lg')]: {
            height: '100%',
            padding: '0 10px',
            alignItems: 'center',
            justifyContent: 'center'
        }
    },
    companyJobStats: {
        justifyContent: 'space-between',
        color: 'var(--grey)',
        '& div': {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('md')]: {
                alignItems: 'flex-start'
            },
            '& span': {
                color: 'var(--dark-grey)'
            }
        }
    },
    companyJobStatus: {
        flexDirection: 'column',
        color: 'var(--grey)',
        [theme.breakpoints.down('md')]: {
            position: 'absolute',
            top: '1rem',
            right: '1.5rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'center'
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: '16px'
        }
    },
    date: {
        color: 'var(--dark-grey)'
    },
    companyJobName: {
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'flex-start',
            paddingLeft: '25px !important'
        },
        [theme.breakpoints.down('md')]: {
            textAlign: 'left',
            margin: '2rem 0 1rem',
            fontSize: '16px'
        }
    },
    jobTypeText: {
        fontSize: 11
    },
    companyJobType: {
        borderRadius: '20px 0 0 20px',
        [theme.breakpoints.up('lg')]: {
            width: '35px',
            '& div': {
                transform: 'rotate(-90deg)',
                textAlign: 'center'
            },
            '&$active': {
                backgroundColor: 'var(--main-color)'
            },
            '&$inactive': {
                color: 'var(--dark-grey)'
            }
        },
        [theme.breakpoints.down('md')]: {
            position: 'absolute',
            top: '1rem',
            '& div': {
                fontSize: '14px'
            },
            '&$active': {
                color: 'var(--black)'
            }
        },
        [theme.breakpoints.only('xs')]: {
            '& div': {
                fontSize: '12px'
            }
        }
    },
    companyJobButtons: {
        justifyContent: 'flex-end',
        '& button$inactive': {
            backgroundColor: 'var(--background-green)',
        },
        [theme.breakpoints.up('lg')]: {
            paddingRight: '25px !important'
        },
        [theme.breakpoints.down('md')]: {
            marginTop: '1rem',
            justifyContent: 'space-between'
        }
    },
    hoverActionContainer: {
        [theme.breakpoints.up('lg')]: {
            padding: '20px'
        },
        [theme.breakpoints.down('md')]: {
            color: 'var(--main-color)',
            display: 'flex',
            margin: '1rem',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%'
        },
        [theme.breakpoints.between('sm', 'md')]: {
            padding: '0 1rem'
        }
    },
    actionIconContainer: {
        display: 'flex',
        [theme.breakpoints.up('lg')]: {
            alignItems: 'flex-start'
        },
        [theme.breakpoints.down('md')]: {
            justifyContent: 'space-between',
            width: '50%'
        }
    },
    statusToggle: {
        display: 'flex',
        color: 'var(--main-color)',
        cursor: 'pointer',
        alignItems: 'center',
        [theme.breakpoints.up('lg')]: {
            marginTop: '10px'
        }
    },
    colorContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '0.7rem',
        height: '100%',
        borderRadius: '10px 0 0 10px',
        '&$inactive': {
            background: 'var(--grey)'
        },
        '&$active': {
            background: 'var(--main-color)'
        }
    },
    checkContainer: {
        width: '35px'
    },
    inactive: {
        color: 'var(--grey)'
    },
    active: {
        color: 'var(--black)'
    },
    waitingApproval: {
        textAlign: 'center',
        color: 'var(--red)'
    },
    lastCandidacyDate: {
        marginRight: '25px',
        marginBottom: '4px',
        display: 'flex',
        justifyContent: 'flex-end'
    }
}))
