import React, { useState } from 'react'
import { Typography, Box } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import { useStyles } from '../CompanyPageImages_style'
import { useWindowSize } from 'hooks/useWindowSize'
import ResponsiveVideo from 'shared/components/ResponsiveVideo/ResponsiveVideo'

const GallerySlide = (props) => {
    const classes = useStyles()
    const { mediaItem } = props
    const { isLargeScreen } = useWindowSize()
    const [video] = useState({
        url: mediaItem.src,
        source: 'youtube',
        sourceId: mediaItem.sourceId,
        width: 850,
        height: 480,
        alt: mediaItem.alt
    })

    if (mediaItem.type === 'video' && mediaItem.format === 'regular') {
        return (
            <Box>
                {isLargeScreen &&
                <Typography className={classes.slideTooltip}>
                    <FormattedMessage id="gallery_slide.quality_tip" />
                </Typography>
                }
                <ResponsiveVideo
                    video={video}
                />
            </Box>
        )
    } else if (mediaItem.type === 'video' && mediaItem.format === 'shorts') {
        return (
            <Box className={classes.vidoContainer}>
                <ResponsiveVideo
                    video={video}
                />
            </Box>
        )
    }
    return <Box className={classes.vidoContainer}>
        <img
            src={mediaItem.src}
            alt={mediaItem.alt}
            style={{
                width: 'auto',
                maxWidth: '100%',
                maxHeight: '80vh',
                borderRadius: 20
            }}
        />
    </Box>
}

export default GallerySlide
