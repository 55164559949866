export const useGalleryImageFormatting = (images, onLoad) => {
    if (!images || images.length < 0) return []

    const imageSpecs = 'c_fill,f_auto,fl_progressive,h_800,q_auto:best'
    return images.map((image, index) => {
        return {
            ...image,
            type: image.type || 'image',
            src: getImageSource(image, imageSpecs),
            alt: `company image ${index}`,
            margin: 0,
            width: image.width,
            height: image.height,
            onLoad: onLoad
        }
    })
}

// PRIVATE

const getImageSource = (image, imageSpecs) => {
    if (image.type === 'video') {
        return image.src
    } else {
        return `https://res.cloudinary.com/studentbe/image/upload/${imageSpecs}/${image.cloudinary_key}`
    }
}
