import { defaultLocale } from '../assets/i18n/default'
import RegexHelper from 'assets/RegexHelper'

const GetLocaleFromUrl = (defaultLang) => {
    const lang = window.location.pathname.match(RegexHelper.languageRegex) && window.location.pathname.match(RegexHelper.languageRegex)[1]
    if (['en', 'fr', 'nl'].includes(lang.toLowerCase())) {
        return lang.toLowerCase()
    } else if (['en', 'fr', 'nl'].includes(defaultLang)) {
        return defaultLang
    } else if (['en', 'fr', 'nl'].includes(defaultLocale)) {
        return defaultLocale
    } else {
        return 'nl'
    }
}

const removeLastSlash = () => {
    const pathname = location.pathname
    return pathname.slice(-1) === '/'
        ? pathname.slice(0, -1)
        : pathname
}

const getJobTypeFromUrl = () => {
    let pathname = location.pathname
    pathname = pathname.slice(1)
    const [_, jobTypePluralized] = pathname.split('/')
    if (jobTypePluralized.match(/jobs-etudiants|studentenjobs|student-jobs/)) { return 'student_job' }
    if (jobTypePluralized.match(/stages|internships/)) { return 'internship' }
    if (jobTypePluralized.match(/premiers-emplois|eerste-jobs|first-jobs/)) { return 'first_job' }

    return null
}

const addEndingBackslashWhenMissing = (url) => {
    if (url.endsWith('/')) {
        return url
    } else {
        return url + '/'
    }
}

const getYouTubeVideoID = (url) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/|.+\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    const match = url.match(regex)
    return match ? match[1] : null
}

const youtubeImageUrlBuilder = (imageId, quality = 'hqdefault') => {
    return `https://img.youtube.com/vi/${imageId}/${quality}.jpg`
}

const EncodeTagToUrl = (tag) => {
    return tag.toLowerCase()
        .replace(/[^\w\u00C0-\u017F]+/g, '-') // Replaces any non char to dash (keeping accents char)
        .replace(/\-\-+/g, '-') // Then replace double dash to single dash.
        .replace(/(^-|-$)/g, '') // Removes any dashes at the start or end of the string
        .replace(/[éèë]/g, 'e')
}

export default {
    GetLocaleFromUrl,
    removeLastSlash,
    getJobTypeFromUrl,
    getYouTubeVideoID,
    youtubeImageUrlBuilder,
    EncodeTagToUrl,
    addEndingBackslashWhenMissing
}
