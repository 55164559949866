export const useStyles = (theme) => ({
    root: {
        display: 'flex'
    },
    linksContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 20
    },
    titlePageContainer: {
        [theme.breakpoints.up('md')]: {
            width: '75%',
            margin: '20px 0'
        }
    },
    dataContainer: {
        display: 'flex'
    },
    dataElemContainer: {
        marginRight: 50
    },
    dataTitle: {
        // color: 'var(--main-color)',
        fontSize: 18
    },
    dataText: {
        color: 'grey',
        fontSize: 14
    },
    studentInfosContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    checkItemsContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-end'
        }
    },
    sendItemsContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'flex-end'
    },
    sendButton: {
        marginLeft: 10
    },
    pageBubble: {
        height: 24,
        width: 24,
        backgroundColor: 'black',
        color: 'white',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 12px',
        cursor: 'pointer'
    },
    active: {
        opacity: '1 !important'
    },
    nonActive: {
        opacity: '0.4 !important'
    }
})
