/* eslint-disable indent */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Image, Transformation } from 'cloudinary-react'
import { Typography, Container, Box, Hidden } from '@material-ui/core'

import Button from 'shared/components/Button/Button'
import { useStyles } from './CompanyEditBanner_style'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import { SMALLER_SIZE } from 'assets/CloudinaryURLHelper'

// eslint-disable-next-line indent
const CompanyEditBanner = (props) => {
    const classes = useStyles()
    const { company } = props

    return (
        <Container maxWidth="lg" className={classes.companyEditBannerContainer}>
            <Box className={classes.companyEditBanner}>
                <Hidden mdDown>
                    <Box className={classes.logoBox}>
                        <Image
                            className={classes.logo}
                            cloudName="studentbe"
                            publicId={company.logo_cloudinary_key ? company.logo_cloudinary_key : 'default-company-logo-black'}
                            alt={`${company.logo_cloudinary_key} logo`}
                        >
                            <Transformation flags="progressive" fetchFormat="auto" width={SMALLER_SIZE} quality="auto:best" crop="scale" />
                        </Image>
                    </Box>
                </Hidden>
                <Box className={classes.companyName}>
                    <Typography component='p' variant='h1' color='primary' className={classes.bannerTitle}>
                        <FormattedMessage id="company_profile.company_profile_banner.title" />
                    </Typography>
                    <Typography component='p' variant='h3' color='primary'>
                        {company.name}
                    </Typography>
                </Box>
            </Box>
            <Box className={classes.buttonContainer} >
                <RouteMapLink page='users/company_profiles/edit'>
                    <Button variant='cta-secondary' size='small' className={classes.customButton}>
                        <FormattedMessage id="company_profile.company_profile_banner.modify_page_button" />
                    </Button>
                </RouteMapLink>
                <RouteMapLink
                    showItemType='company'
                    showItemUrl={company.url}
                >
                    <Button variant='cta-primary' size='small' className={classes.customButton}>
                        <FormattedMessage id="company_profile.company_profile_banner.button" />
                    </Button>
                </RouteMapLink>
            </Box>
        </Container>
    )
}

export default CompanyEditBanner
