import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    container: {
        border: '1px solid var(--background-silver)',
        borderRadius: 'var(--border-radius)',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 20,
        justifyContent: 'space-evenly',
        padding: '24px 10px'
    },
    companyStat: {
        width: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    companyStatText: {
        color: 'var(--foreground-black-60-opacity)',
        textTransform: 'capitalize'
    },
    companyStatNumber: {
        fontFamily: 'GreedWide-Bold',
        fontSize: 20
    }
}))
