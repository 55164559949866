import React from 'react'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './MultiStepRegister_style'
import { withStyles } from '@material-ui/core/styles'
import { Box, Typography, Card, CardContent, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'

import FilledButton from 'buttons/FilledButton/FilledButton'
import StudentRegister from '../StudentRegister/StudentRegister'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import CollectionHandler from 'assets/CollectionHandler.js'
import RouteMap from 'assets/RouteMap.js'
import URLParamsHandler from 'assets/URLParamsHandler'
import JoinCompanyModal from 'shared/modals/JoinCompanyModal/JoinCompanyModal'
import Button from 'shared/components/Button/Button'
import CompanySectorMultipleSelect from './components/CompanySectorMultipleSelect/CompanySectorMultipleSelect'
import CompanySizeSelect from './components/CompanySizeSelect/CompanySizeSelect'
import Input from './components/Input/Input'

class MultiStepRegister extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            step: 1,
            user: this.props.user,
            userType: this.props.userType === null ? null : this.props.userType,
            studentRegister: this.props.userType === 'student',
            employerRegister: false,
            isSubmitting: false,
            firstName: '', // TODO: To change
            lastName: '',
            companyId: 0,
            companyName: '',
            companySize: '',
            companySectors: [],
            phoneNumber: '',
            errorsList: {},
            lockUserTypesVisibility: false,
            employerModalOpen: false,
            matchingCompanies: [],
        }

        this.companySizeCollection = CollectionHandler.Get('companySizeCollection')

        this.funnelPath = this.calculateFunnelPathType()
        this.calculateMaxStep()
        this.nextStep = this.nextStep.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.canSubmit = this.canSubmit.bind(this)
        this.frontValidation = this.frontValidation.bind(this)
        this.redirectUser = this.redirectUser.bind(this)
        this.openJoinCompanyModal = this.openJoinCompanyModal.bind(this)
    }

    componentDidMount () {
        window.scrollTo(0, 0)
        axios.defaults.headers.common[
            'X-CSRF-TOKEN'
        ] = ReactOnRails.authenticityToken()
    }

    openJoinCompanyModal () {
        this.setState({ employerModalOpen: true })
    }

    calculateFunnelPathType () {
        var funnelPath
        funnelPath = 'job_path'
        return funnelPath
    }

    calculateMaxStep () {
        switch (this.funnelPath) {
        case 'job_with_motivation_path' || 'student_path':
            this.maxStep = 4
            break
        case 'student_with_motivation_path':
            this.maxStep = 5
            break
        case 'job_path':
            this.maxStep = 3
            break
        }
    }

    nextStep (value) {
        window.scrollTo(0, 0)
        if (value > this.maxStep) {
            window.location.href = '/'
        } else {
            if (value === 4 && this.funnelPath === 'job_with_motivation_path') {
                value = value + 1
            }
            this.setState({
                step: value
            })
        }
    }

    canSubmit () {
        var canSubmit
        if (this.state.userType === 'student') {
            canSubmit = true
        } else if (this.state.userType === 'kot_owner') {
            canSubmit = this.state.firstName &&
            this.state.lastName
        } else if (this.state.userType === 'employer') {
            canSubmit = this.state.firstName &&
            this.state.lastName &&
            this.state.companyName &&
            this.state.companySize &&
            (this.state.companySectors.length !== 0) &&
            this.state.phoneNumber
        }

        return canSubmit
    }

    frontValidation () {
        const {
            userType,
            firstName,
            lastName,
            phoneNumber,
            companyName,
            companySize,
            companySectors
        } = this.state

        var errorsList = {}
        if (userType === 'student') {
            if (firstName === '') {
                errorsList.first_name = <FormattedMessage id='landing_login_page.errors.first_name' />
            }
            if (lastName === '') {
                errorsList.last_name = <FormattedMessage id='landing_login_page.errors.last_name' />
            }
        } else if (userType === 'kot_owner') {
            if (firstName === '') {
                errorsList.first_name = <FormattedMessage id='landing_login_page.errors.first_name' />
            }
            if (lastName === '') {
                errorsList.last_name = <FormattedMessage id='landing_login_page.errors.last_name' />
            }
        } else if (userType === 'employer') {
            if (firstName === '') {
                errorsList.first_name = <FormattedMessage id='landing_login_page.errors.first_name' />
            }
            if (lastName === '') {
                errorsList.last_name = <FormattedMessage id='landing_login_page.errors.last_name' />
            }
            if (companyName === '') {
                errorsList.name = <FormattedMessage id='landing_login_page.errors.name' />
            }
            if (companySize === '') {
                errorsList.size = <FormattedMessage id='landing_login_page.errors.size' />
            }
            if (companySectors.length === 0) {
                errorsList.sectors = <FormattedMessage id='landing_login_page.errors.sector' />
            }
            if (phoneNumber === '') {
                errorsList.phone_number = <FormattedMessage id='landing_login_page.errors.phone_number' />
            }
        }
        this.setState({ errorsList: errorsList })
    }

    updateProfile () {
        if (this.state.isSubmitting) return

        if (this.canSubmit()) {
            this.setState({ isSubmitting: true })
            const {
                userType,
                firstName,
                lastName,
                phoneNumber,
                companyName,
                companySize,
                companySectors
            } = this.state
            axios({
                url: this.props.urls.updateProfileUrl,
                method: 'post',
                data: {
                    user_type: userType,
                    first_name: firstName,
                    last_name: lastName,
                    phone_number: phoneNumber,
                    name: companyName,
                    size: companySize,
                    sectors: companySectors
                }
            }).then(
                response => {
                    if (response?.data?.user?.id) {
                        AnalyticsHelper.sendAnalyticsEvent('identify', { user_id: response.data.user.id })
                        if (this.state.userType !== 'student') {
                            AnalyticsHelper.sendAnalyticsEvent('track', {
                                user_id: response.data.user.id,
                                event_name: 'Signup Completed',
                                custom_props: { origin: this.props.origin }
                            })
                        }
                    }
                    switch (this.state.userType) {
                    case 'student':
                        this.setState({ studentRegister: true, userID: response?.data?.user?.id })
                        break
                    case 'kot_owner':
                        AnalyticsHelper.sendGTMEvent('registration-success', {
                            user_type: 'kot_owner',
                            language: RouteMap.GetLocaleFromUrl()
                        })
                        AnalyticsHelper.sendGTMEvent(
                            'Create Account Completed - Kot Owner',
                            AnalyticsHelper.constructRegisterOwnerAnalyticsAttributes(
                                { // Construct User object
                                    id: response?.data?.user?.id,
                                    type: userType
                                },
                                this.props.loginType,
                                this.props.origin
                            ))

                        this.redirectUser(RouteMap.Page('users/kots'))
                        break
                    case 'employer':
                        AnalyticsHelper.sendGTMEvent('registration-success', {
                            user_type: 'employer',
                            language: RouteMap.GetLocaleFromUrl()
                        })
                        AnalyticsHelper.sendGTMEvent(
                            'Create Account Completed - Employer',
                            AnalyticsHelper.constructRegisterEmployerAnalyticsAttributes(
                                { // Construct User object
                                    id: response?.data?.user?.id,
                                    type: userType
                                },
                                this.props.loginType,
                                this.props.origin,
                                { // Construct Company object
                                    id: response?.data?.company_id,
                                    name: companyName,
                                    sector: companySectors,
                                    size: companySize
                                }
                            ))

                        const matchingCompanies = response.data.matching_companies
                        if (matchingCompanies) {
                            this.setState({ matchingCompanies: matchingCompanies, companyId: response.data.company_id })
                            this.openJoinCompanyModal()
                        } else if (URLParamsHandler.parseURLParams().redirect) { // Direct redirection to required page
                            URLParamsHandler.redirectWithURL()
                        } else {
                            // Redirection happening in the welcome page
                            window.location.href = `${RouteMap.Page('users')}welcome?redirect=${this.props.userStoredLocation}`
                        }
                        break
                    }
                },
                error => {
                    if (error.response.data.error) {
                        this.setState({ errorsList: error.response.data.error, isSubmitting: false })
                    }
                }
            )
        } else {
            this.setState({ isSubmitting: false })
            this.frontValidation()
        }
    }

    handleSubmit (event) {
        switch (this.state.userType) {
        case 'student':
            this.updateProfile()
            break
        case 'kot_owner':
            this.updateProfile()
            break
        case 'employer':
            this.updateProfile()
            break
        default:
            this.updateProfile()
            break
        }
    };

    handleChange (event) {
        const { name, value } = event.target
        if (name === 'userType') {
            this.state.lockUserTypesVisibility = true
        }
        this.setState({
            [name]: value
        })
    }

    renderKotOwnerInfos () {
        const { classes } = this.props

        return (
            <form
                className={classes.form}
                action=''
                onSubmit={event => event.preventDefault()}
            >
                <Input
                    id='first-name'
                    name='firstName'
                    labelTextId='landing_login_page.sign_up.inputs.first_name_placeholder'
                    required
                    error={this.state.errorsList.first_name}
                    handleChange={this.handleChange}
                />
                <Input
                    id='last-name'
                    name='lastName'
                    labelTextId='landing_login_page.sign_up.inputs.last_name_placeholder'
                    required
                    error={this.state.errorsList.last_name}
                    handleChange={this.handleChange}
                />
                <Input
                    id='phone-number'
                    name='phoneNumber'
                    labelTextId='landing_login_page.sign_up.inputs.phone_number_placeholder'
                    handleChange={this.handleChange}
                    error={this.state.errorsList.phone_number}
                />
            </form>
        )
    }

    renderStudentInfos () {
        return (
            null
        )
    }

    renderEmployerInfos () {
        const { classes } = this.props

        return (
            <Box className={classes.employerInfoStep}>
                <JoinCompanyModal
                    newCompanyName={this.state.companyName}
                    userStoredLocation={this.props.userStoredLocation}
                    companyId={this.state.companyId}
                    matchingCompanies={this.state.matchingCompanies}
                    employerModalOpen={this.state.employerModalOpen}
                    fullName={`${this.state.firstName} ${this.state.lastName}`}
                />
                <form
                    className={classes.form}
                    action=''
                    onSubmit={event => event.preventDefault()}
                >
                    <Input
                        id='first-name'
                        name='firstName'
                        labelTextId='landing_login_page.sign_up.inputs.first_name_placeholder'
                        required
                        error={this.state.errorsList.first_name}
                        handleChange={this.handleChange}
                    />
                    <Input
                        id='last-name'
                        name='lastName'
                        labelTextId='landing_login_page.sign_up.inputs.last_name_placeholder'
                        required
                        error={this.state.errorsList.last_name}
                        handleChange={this.handleChange}
                    />
                    <Input
                        id='company-name'
                        name='companyName'
                        labelTextId='landing_login_page.sign_up.inputs.company_name_placeholder'
                        required
                        error={this.state.errorsList.name}
                        handleChange={this.handleChange}
                    />
                    <CompanySectorMultipleSelect
                        values={this.state.companySectors}
                        handleChange={this.handleChange}
                        name='companySectors'
                        error={this.state.errorsList.sectors}
                    />
                    <Input
                        id='phone-number'
                        name='phoneNumber'
                        labelTextId='landing_login_page.sign_up.inputs.phone_number_placeholder'
                        required
                        error={this.state.errorsList.phone_number}
                        handleChange={this.handleChange}
                    />
                    <CompanySizeSelect
                        name='companySize'
                        options={this.companySizeCollection}
                        handleChange={this.handleChange}
                        error={this.state.errorsList.size}
                    />
                </form>
            </Box >
        )
    }

    redirectUser (redirectionUrl = null) {
        if (this.props.userStoredLocation) {
            window.location.href = this.props.userStoredLocation
        } else {
            window.location.href = redirectionUrl
        }
    }

    renderGeneralUserInfos () {
        switch (this.state.userType) {
        case 'student':
            return this.renderStudentInfos()
        case 'employer':
            return this.renderEmployerInfos()
        case 'kot_owner':
            return this.renderKotOwnerInfos()
        }
    }

    renderUserFunnelRegister () {
        const { classes } = this.props

        if (this.state.studentRegister || this.props.origin === 'job_application') {
            return this.renderStudentFunnel()
        } else if (this.state.userType === 'employer' && this.state.lockUserTypesVisibility === false) {
            return (
                <>
                    {this.renderEmployerInfos()}
                    <FilledButton newStyle name={<FormattedMessage id='landing_login_page.next' />} onClick={this.handleSubmit} />
                </>
            )
        } else {
            return (
                <CardContent className={classes.step1}>
                    {!(this.props.userStoredLocation && this.props.userStoredLocation.replace(/^\/(fr|en|nl)/g, '') !== '/users/kots/new') &&
                        <FormControl component='fieldset'>
                            <FormLabel component='legend' className={classes.stepTitle}>
                                <FormattedMessage id='landing_login_page.sign_up.step1.title' />
                            </FormLabel>
                            <RadioGroup className={classes.step1RadioGroup} aria-label='user type' name='userType' value={this.state.userType} onChange={this.handleChange} >
                                <FormControlLabel classes={{ root: classes.formControlLabelRoot, label: classes.formControlLabelText }} value='student' control={<Radio classes={{ root: classes.radioRoot, }} />} label={<FormattedMessage id='landing_login_page.sign_up.option_student' />} />
                                <FormControlLabel classes={{ root: classes.formControlLabelRoot, label: classes.formControlLabelText }} value='employer' control={<Radio classes={{ root: classes.radioRoot, }} />} label={<FormattedMessage id='landing_login_page.sign_up.option_employer' />} />
                                <FormControlLabel classes={{ root: classes.formControlLabelRoot, label: classes.formControlLabelText }} value='kot_owner' control={<Radio classes={{ root: classes.radioRoot, }} />} label={<FormattedMessage id='landing_login_page.sign_up.option_kot_owner' />} />
                            </RadioGroup>
                        </FormControl>
                    }
                    {this.renderGeneralUserInfos()}
                    <Button className={classes.submitButton} onClick={this.handleSubmit} >
                        <FormattedMessage id='landing_login_page.next' />
                    </Button>
                </CardContent>
            )
        }
    }

    renderStudentFunnel () {
        const { classes } = this.props

        return (
            <CardContent className={classes.studentRegisterStep}>
                <StudentRegister
                    origin={this.props.origin}
                    loginType={this.props.loginType}
                    userID={this.state.userID}
                    jobInfo={this.props.jobInfo}
                    afterSignInAction={this.props.afterSignInAction || this.props.afterAuthAction}
                />
            </CardContent>
        )
    }

    render() {
        const { classes } = this.props

        return (
            <Card className={classes.multiStepRegisterCard}>
                {false &&
                    <Typography
                        variant='h1'
                        color='primary'
                    >
                        <FormattedMessage id='landing_login_page.sign_up.title' />
                    </Typography>
                }
                {this.renderUserFunnelRegister()}
            </Card>
        )
    }
}

export default withStyles(useStyles)(MultiStepRegister)
