import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    checkbox: {
        overflow: 'hidden',
        width: '20px',
        height: '19px',
        backgroundColor: 'white',
        border: '2px solid black',
        borderRadius: '5px',
        padding: 0,
    }
}))
