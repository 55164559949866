import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    letsChatContainer: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            maxWidth: '341px'
        },
        padding: '23px 29px 32px',
        borderRadius: '20px',
        backgroundColor: theme.palette.bg.yellow
    },

    title: {
        paddingBottom: '14px',
        color: theme.palette.fg.black,
        fontSize: '18px',
        lineHeight: '37px'
    },

    message: {
        paddingBottom: '14px',
        color: theme.palette.fg.black,
        fontSize: '16px',
        lineHeight: '18px'
    },

    submitButton: {
        width: '100%'
    }
}))
