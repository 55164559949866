/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Bugsnag from '@bugsnag/js'

import { FormattedMessage } from 'react-intl'
import { Autocomplete } from '@material-ui/lab'
import { Container, Grid, Box, Checkbox, TextField, FormControlLabel, Button as MuiButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import PopupFactory from 'modals/Popup/PopupFactory'
import Button from 'shared/components/Button/Button'
import SectionTitle from 'components/SectionTitle/SectionTitle'
import CompanyProfileFormDescription from './steps/CompanyProfileFormDescription'
import CompanyProfilesGeneralInfo from './steps/CompanyProfileFormGeneralInfo'
import CompanyProfileFormImage from './steps/CompanyProfileFormImage'
import CompanyProfileFormBannerImage from './steps/CompanyProfileFormBannerImage'
import CompanyAPI from '../../../../api/CompanyAPI'
import { useGalleryImageFormatting } from 'hooks/useGalleryImageFormatting'
import RouteMap from 'assets/RouteMap'
import FeedbackSnackbar from 'components/FeedbackSnackbar/FeedbackSnackbar'
import IconFactory from '../../../shared/icons/IconFactory/IconFactory'
import CollectionHandler from 'assets/CollectionHandler'
import { useStyles } from './CompanyProfileForm_style'
import Field from '../../../shared/inputs/Field/Field'
import CompanyPageImages from '../CompanyPage/CompanyPageImages/CompanyPageImages'

const CompanyProfileForm = (props) => {
    const { classes } = props
    const [renderPopup, setRenderPopup] = useState(false)
    const [formErrors, setFormErrors] = useState(null)
    const scrollToError = () => {
        const firstError = document.getElementsByClassName('error')[0]
        firstError.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
    const [feedback, setFeedback] = useState({ open: false, severity: 'success', message: null })
    const [admin] = useState(props.admin)
    const [companies] = useState(props.companies)
    const [childCompanies, setChildCompanies] = useState(props.childCompanies || [])
    const [company, setCompany] = useState({
        id: props.company.id,
        name: props.company.name || '',
        sectors: props.company.sectors || [],
        size: props.company.size || '',
        street: props.company.address.street || '',
        zip: props.company.address.zip || '',
        city: props.company.address.city || '',
        country: props.company.address.country || CollectionHandler.Get('countryCollection')[RouteMap.GetLocaleFromUrl()],
        bannerImageId: props.company.banner_image_cloudinary_key,
        mainShowVideo: props.company.main_show_video,
        companyImages: props.company.main_image ? [props.company.main_image] : [],
        galleryImages: props.company.gallery_images,
        mainVideoUrlEn: props.company.main_video_url_en,
        mainVideoUrlFr: props.company.main_video_url_fr,
        mainVideoUrlNl: props.company.main_video_url_nl,
        descriptionFR: props.company.description_fr || '',
        descriptionEN: props.company.description_en || '',
        descriptionNL: props.company.description_nl || '',
        companyLogo: props.company.logo || null,
        color: props.company.color || 'var(--black)',
        nbrCountries: props.company.nbr_countries || 1,
        nbrEmployees: props.company.nbr_employees || '18 à 28',
        averageAges: props.company.employees_average_ages || '18 à 45',
        nbrYoungGraduatedPerYear: props.company.nbr_young_graduated_hired_per_year || '25 à 60',
        ceoName: props.company.ceo_name || '',
        nbrEmployeesBelow30: props.company.nbr_employees_below_thirthy || '22',
        salesRevenue: props.company.sales_revenues || 50000,
        nbrCheckedCheckboxes: props.company.featured_characteristics?.length || 0,
        selectedFields: props.company.featured_characteristics || [],
        email: props.company.email,
        vat: props.company.vat,
        isParent: props.company.is_parent,
        showCompanyPage: props.company.show_profile,
        showCompanyPageEN: props.company.en,
        showCompanyPageFR: props.company.fr,
        showCompanyPageNL: props.company.nl,
        showArticles: props.company.show_articles,
        createArticles: props.company.create_articles,
        showHeadhunt: props.company.show_headhunt
    })
    const formattedImages = useGalleryImageFormatting(company.galleryImages)

    useEffect(() => {
        const errorsPresent = formErrors && Object.keys(formErrors).length > 0
        if (errorsPresent) scrollToError()
    }, [formErrors])

    const updateBannerImageId = (document_object) => {
        axios({
            url: '/api/v1/users/companies',
            method: 'patch',
            data: {
                banner_image_id: document_object.id,
                id: company.id
            }
        }).then((response) => {
            setCompany({
                ...company,
                bannerImageId: response.data.banner_image_cloudinary_key
            })
        }).catch((error) => {
            alert(`An error has occured: ${error}`)
            Bugsnag.notify(error)
        })
    }

    const updatePictureId = (info) => {
        axios({
            url: '/api/v1/documents',
            method: 'post',
            data: {
                cloudinary_key: info.public_id,
                format: info.format,
                is_image: true,
                is_used: false
            }
        }).then((response) => {
            updateBannerImageId(response.data.document_object)
        }).catch((error) => {
            alert(`An error has occured: ${error}`)
            Bugsnag.notify(error)
        })
    }

    const handleChange = (event) => {
        const { name, value } = event.target
        setCompany({
            ...company,
            [name]: value
        })
    }
    const handleCompanyLogoChange = (newCompanyLogo) => {
        setCompany({
            ...company,
            companyLogo: newCompanyLogo
        })
    }

    const handleCompanyVideoChange = () => {
        setCompany({
            ...company,
            mainShowVideo: !company.mainShowVideo
        })
    }

    const handleImagesChange = (images) => {
        setCompany({ ...company, companyImages: images })
    }

    const handleQuillChange = (value, language) => {
        setCompany({
            ...company,
            ['description' + language]: value
        })
    }

    const handleChangeCheckBox = (event) => {
        const { value, checked } = event.target
        const nbrChecked = company.nbrCheckedCheckboxes
        const toAdd = (checked ? 1 : -1)
        const newSelectedFields = company.selectedFields
        const targetIndex = newSelectedFields.indexOf(value)
        targetIndex === -1 ? newSelectedFields.push(value) : newSelectedFields.splice(targetIndex, 1)

        const checkboxes = document.querySelectorAll('[name=checkbox]')
        if (nbrChecked + toAdd === 3) {
            checkboxes.forEach((checkbox) => {
                if (!checkbox.checked) checkbox.disabled = true
            })
        } else if (nbrChecked === 3 && nbrChecked + toAdd < 3) {
            checkboxes.forEach((checkbox) => {
                if (checkbox.disabled) checkbox.disabled = false
            })
        }
        setCompany({
            ...company,
            selectedFields: newSelectedFields,
            nbrCheckedCheckboxes: nbrChecked + toAdd
        })
    }

    const frontValidation = (company) => {
        const errors = {}
        if (!company.name) errors.companyName = <FormattedMessage id="company_profile.edit_profile.errors.company_name" />
        if (company.sectors.length === 0) errors.companySectors = <FormattedMessage id="company_profile.edit_profile.errors.company_sectors" />
        if (!company.size) errors.companySize = <FormattedMessage id="company_profile.edit_profile.errors.company_size" />
        if (!company.city) errors.companyCity = <FormattedMessage id="company_profile.edit_profile.errors.company_city" />
        const hasErrors = Object.entries(errors).length > 0
        hasErrors ? setFormErrors(errors) : setFormErrors(null)

        return { hasErrors }
    }

    const submitUpdates = (images) => {
        axios({
            url: '/api/v1/users/companies',
            method: 'patch',
            data: {
                gallery_images: images,
                id: company.id
            }
        }).then((response) => {
            setRenderPopup(!renderPopup)
            setCompany({
                ...company,
                galleryImages: response.data.gallery_images
            })
        }).catch((error) => {
            alert(`An error has occured: ${error}`)
            Bugsnag.notify(error)
        })
    }

    const closeFeedback = () => setFeedback({ ...feedback, open: false })

    const submitEdit = () => {
        const validation = frontValidation(company)
        if (validation.hasErrors) return

        const axiosData = {
            id: company.id,
            city: company.city,
            street: company.street,
            zip: company.zip,
            sectors: company.sectors,
            size: company.size,
            name: company.name,
            description_fr: company.descriptionFR,
            description_en: company.descriptionEN,
            description_nl: company.descriptionNL,

            featured_characteristics: company.selectedFields,
            color: company.color,
            nbr_countries: company.nbrCountries,
            nbr_employees: company.nbrEmployees,
            employees_average_ages: company.averageAges,
            nbr_young_graduated_hired_per_year: company.nbrYoungGraduatedPerYear,
            ceo_name: company.ceoName,
            nbr_employees_below_thirthy: company.nbrEmployeesBelow30,
            sales_revenues: company.salesRevenue,

            main_video_url_en: company.mainVideoUrlEn,
            main_video_url_fr: company.mainVideoUrlFr,
            main_video_url_nl: company.mainVideoUrlNl,
            main_show_video: company.mainShowVideo,
            gallery_images: company.galleryImages,
            logo_id: company.companyLogo?.id,
            admin: admin,
            email: company.email,
            vat: company.vat,
            is_parent: company.isParent,
            show_profile: company.showCompanyPage,
            en: company.showCompanyPageEN,
            fr: company.showCompanyPageFR,
            nl: company.showCompanyPageNL,
            show_articles: company.showArticles,
            create_articles: company.createArticles,
            show_headhunt: company.showHeadhunt,
            child_companies: childCompanies.map((company) => company.id)
        }

        if (!company.mainShowVideo && company.companyImages.length > 0) {
            axiosData.main_image_id = company.companyImages.pop().id
        }

        CompanyAPI.CompanyProfileUpdate(axiosData).then(() => {
            setFeedback({
                open: true,
                severity: 'success',
                message: <FormattedMessage id="company_profile.edit_profile.success" />
            })
            if (admin) {
                window.location.reload()
            } else {
                window.location.href = RouteMap.Edit('company')
            }
        }).catch((error) => {
            setFeedback({
                open: true,
                severity: 'error',
                message: <FormattedMessage id='activerecord.errors.messages.record_invalid' values={{ errors: error.response.data.values }} />
            })
        })
    }

    return (
        <Container maxWidth='lg'>
            <PopupFactory
                value={company.galleryImages}
                companyId={company.id}
                type="manage-company-pictures"
                popupExit={() => setRenderPopup(!renderPopup)}
                actionPopup={submitUpdates}
                openShare={renderPopup}
            />
            <FeedbackSnackbar
                open={feedback.open}
                message={feedback.message}
                severity={feedback.severity}
                closeFeedback={closeFeedback}
            />
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box className={classes.topOfPageButtons}>
                        <MuiButton
                            startIcon={<IconFactory icon='back' />}
                            color={'primary'}
                            size={'small'}
                            href={RouteMap.Page('users/company/edit')}
                        >
                            <FormattedMessage id="company_profile.edit_profile.back_button" />
                        </MuiButton>
                        <Button variant='cta-secondary' size='small' className={classes.submitButton} onClick={submitEdit}>
                            <FormattedMessage id="company_profile.edit_profile.submit_button" />
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box className={classes.titleContainer}>
                        <SectionTitle
                            step={1}
                            title={<FormattedMessage id="company_profile.company_page_description.description_popup.title" />}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box className={classes.BlockContainer}>
                        <CompanyProfileFormDescription
                            company={company}
                            handleChange={handleChange}
                            handleQuillChange={handleQuillChange}
                            errors={formErrors}
                        />
                    </Box>
                    <Box className={`${classes.BlockContainer} ${classes.SectionMargin}`}>
                        <CompanyProfileFormImage
                            company={company}
                            handleChange={handleChange}
                            handleVideoChange={handleCompanyVideoChange}
                            handleImagesChange={handleImagesChange}
                        />
                    </Box>
                    <Box className={`${classes.BlockContainer} ${classes.SectionMargin}`}>
                        <CompanyProfileFormBannerImage
                            companyName={company.name}
                            bannerImageId={company.bannerImageId}
                            updatePictureId={updatePictureId}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box className={classes.titleContainer}>
                        <SectionTitle
                            step={2}
                            title={<FormattedMessage id="company_profile.employer_banner.info_manager_popup.title" />}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box className={classes.BlockContainer}>
                        <CompanyProfilesGeneralInfo
                            company={company}
                            handleChange={handleChange}
                            handleChangeCheckBox={handleChangeCheckBox}
                            handleCompanyLogoChange={handleCompanyLogoChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box className={classes.titleContainer}>
                        <SectionTitle
                            step={3}
                            title={<FormattedMessage id="company_profile.company_page_description.video_manager_pop_up.title" />}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box className={classes.BlockContainer}>
                        <Button variant='cta-primary' size= 'small' onClick={() => { setRenderPopup(true) }}>
                            <FormattedMessage id="company_profile.company_page_images.add_photos" />
                        </Button>
                    </Box>
                </Grid>
                {company.galleryImages &&
                    <Grid item xs={12}>
                        <Box className={classes.BlockContainer}>
                            <CompanyPageImages images={formattedImages} />
                        </Box>
                    </Grid>
                }

                {admin &&
                    <Grid item xs={12} md={12}>
                        <Field
                            name="email"
                            title={'Email'}
                            placeholder={'Enter email'}
                            value={company.email}
                            handleChange={handleChange}
                        />
                        <Field
                            name="vat"
                            title={'VAT'}
                            placeholder={'Enter VAT'}
                            value={company.vat}
                            handleChange={handleChange}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color='primary'
                                    icon={<IconFactory icon='circle' />}
                                    checkedIcon={<IconFactory icon='circle-full' />}
                                    checked={company.isParent}
                                    name='active'
                                    value={company.isParent}
                                    onChange={() => { setCompany({ ...company, isParent: !company.isParent }) }}
                                />
                            }
                            label={'Is parent'}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color='primary'
                                    icon={<IconFactory icon='circle' />}
                                    checkedIcon={<IconFactory icon='circle-full' />}
                                    checked={company.showCompanyPage}
                                    name='active'
                                    value={company.showCompanyPage}
                                    onChange={() => { setCompany({ ...company, showCompanyPage: !company.showCompanyPage }) }}
                                />
                            }
                            label={'Show company page'}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color='primary'
                                    icon={<IconFactory icon='circle' />}
                                    checkedIcon={<IconFactory icon='circle-full' />}
                                    checked={company.showCompanyPageEN}
                                    name='active'
                                    value={company.showCompanyPageEN}
                                    onChange={() => { setCompany({ ...company, showCompanyPageEN: !company.showCompanyPageEN }) }}
                                />
                            }
                            label={'Show company page in EN'}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color='primary'
                                    icon={<IconFactory icon='circle' />}
                                    checkedIcon={<IconFactory icon='circle-full' />}
                                    checked={company.showCompanyPageFR}
                                    name='active'
                                    value={company.showCompanyPageFR}
                                    onChange={() => { setCompany({ ...company, showCompanyPageFR: !company.showCompanyPageFR }) }}
                                />
                            }
                            label={'Show company page in FR'}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color='primary'
                                    icon={<IconFactory icon='circle' />}
                                    checkedIcon={<IconFactory icon='circle-full' />}
                                    checked={company.showCompanyPageNL}
                                    name='active'
                                    value={company.showCompanyPageNL}
                                    onChange={() => { setCompany({ ...company, showCompanyPageNL: !company.showCompanyPageNL }) }}
                                />
                            }
                            label={'Show company page in NL'}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color='primary'
                                    icon={<IconFactory icon='circle' />}
                                    checkedIcon={<IconFactory icon='circle-full' />}
                                    checked={company.showArticles}
                                    name='active'
                                    value={company.showArticles}
                                    onChange={() => { setCompany({ ...company, showArticles: !company.showArticles }) }}
                                />
                            }
                            label={'Show articles'}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color='primary'
                                    icon={<IconFactory icon='circle' />}
                                    checkedIcon={<IconFactory icon='circle-full' />}
                                    checked={company.createArticles}
                                    name='active'
                                    value={company.createArticles}
                                    onChange={() => { setCompany({ ...company, createArticles: !company.createArticles }) }}
                                />
                            }
                            label={'Create articles'}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color='primary'
                                    icon={<IconFactory icon='circle' />}
                                    checkedIcon={<IconFactory icon='circle-full' />}
                                    checked={company.showHeadhunt}
                                    name='active'
                                    value={company.showHeadhunt}
                                    onChange={() => { setCompany({ ...company, showHeadhunt: !company.showHeadhunt }) }}
                                />
                            }
                            label={'Show headhunt'}
                        />
                        <Autocomplete
                            disablePortal
                            id="child-companies-field"
                            value={childCompanies}
                            multiple={true}
                            options={companies}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => setChildCompanies(value)}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Child companies" />}
                        />
                    </Grid>
                }

                <Grid item xs={12} >
                    <Box className={classes.buttonContainer}>
                        <Button variant='cta-secondary' size= 'small' className={classes.submitButton} onClick={submitEdit}>
                            <FormattedMessage id="company_profile.edit_profile.submit_button" />
                        </Button>
                    </Box>
                    {formErrors &&
                        <Box className={classes.errors}>
                            <FormattedMessage id="company_profile.edit_profile.errors.present" />
                        </Box>
                    }
                </Grid>
            </Grid>
        </Container>
    )
}

export default withStyles(useStyles)(CompanyProfileForm)
