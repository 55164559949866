import React from 'react'
import Lottie from 'lottie-react'

import { Box } from '@material-ui/core'

import backpackLottie from './lotties/BAGPACK-lottie.json'
import rocketLottie from './lotties/ROCKET.json'

const lottieSwitch = (lottie) => {
    switch (lottie) {
    case 'backpack': return backpackLottie
    case 'rocket': return rocketLottie

    default: return '/'
    }
}

const LottieFactory = ({ lottie }) => {
    return (
        <Box>
            <Lottie animationData={lottieSwitch(lottie)}/>
        </Box>
    )
}

export default LottieFactory
