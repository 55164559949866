import React, { useState, useContext, useEffect, useRef } from 'react'
import ReactOnRails from 'react-on-rails'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './jobShow_style'
import { Paper, Container, Box, Typography, Hidden } from '@material-ui/core'

import { ApplyProcessContext, useApplyProcessContext } from 'shared/contexts/ApplyProcessContext'
import { JOB_SHOW } from 'assets/AuthentificationHelper'
import { LoginRegisterContext } from 'shared/contexts/LoginRegisterContext'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import ApplyButton from './components/ApplyButton/ApplyButton'
import BottomAd from './components/BottomAd/BottomAd'
import ChatWindow from 'components/ChatWindow/ChatWindow'
import CompanyInfoCard from './components/CompanyInfoCard/CompanyInfoCard'
import CompanyOtherJobs from 'shared/components/CompanyOtherJobs/CompanyOtherJobs'
import CTADashboard from 'shared/components/CTADashboard/CTADashboard'
import HalfPageAds from './components/HalfPageAds/HalfPageAds'
import JobPassport from './components/JobPassport/JobPassport'
import JobShowBanner from './components/JobShowBanner/JobShowBanner'
import JobShowCompanyImages from './components/JobShowCompanyImages/JobShowCompanyImages'
import JobShowContent from './components/JobShowContent/JobShowContent'
import JobShowHelmet from 'helmets/JobShowHelmet'
import JobShowMedia from './components/JobShowMedia/JobShowMedia'
import LeaderBoardAd from './components/LeaderBoardAd/LeaderBoardAd'
import LetsChatCard from './components/LetsChatCard/LetsChatCard'
import NewsAndTips from 'shared/components/NewsAndTips/NewsAndTips'
import PopupFactory from 'modals/Popup/PopupFactory'
import PreviewBanner from './components/PreviewBanner/PreviewBanner'
import RecommendedJobs from 'shared/components/RecommendedJobs/RecommendedJobs'
import URLParamsHandler from 'assets/URLParamsHandler.js'
import VerticalVideos from 'components/VerticalVideos/VerticalVideos'

const JobShow = (props) => {
    const { user, job, ads, unavailable } = props
    const classes = useStyles()
    const [openChat, setOpenChat] = useState(user?.type === 'student' && URLParamsHandler.loadProcessFromURL() === 'message_to_employer')
    const loginRegisterContext = useContext(LoginRegisterContext)
    const applyProcessContext = useApplyProcessContext(props)
    const [, setCompanyImages] = useState([])
    const verticalVideosDivRef = useRef(null)

    useEffect(() => {
        axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
        AnalyticsHelper.sendGTMEvent(
            'Job Details Viewed',
            AnalyticsHelper.constructJobDetailsViewedAnalyticsAttributes(user, job, {
                id: job.company_id,
                name: job.company_name,
                sector: job.sector,
                size: job.company_size
            })
        )
    }, [])

    useEffect(() => {
        if (job.type === 'student_job') {
            const shuffledImages = job.company_images.sort(() => 0.5 - Math.random())
            const selectedImages = shuffledImages.slice(0, 2)
            setCompanyImages(selectedImages)
        } else {
            setCompanyImages(job.company_images)
        }
    }, [])

    const openLoginRegisterModal = () => {
        loginRegisterContext.openLoginRegisterModal({
            origin: JOB_SHOW,
            afterAuthAction: afterAuthAction
        })
    }

    const afterAuthAction = () => {
        URLParamsHandler.reloadPageAndAddParams({ process: 'message_to_employer' })
    }

    const handleOpenChat = (value) => {
        if (!user) {
            openLoginRegisterModal()
        } else {
            setOpenChat(value)
        }
    }

    return (
        <>
            <JobShowHelmet job={job} noindex={unavailable} />
            <ApplyProcessContext.Provider value={applyProcessContext}>
                {/* ===== MODALS ===== */}
                {applyProcessContext.showApplyModal &&
                    <PopupFactory
                        type="apply-popup"
                        popupExit={applyProcessContext.toggleApplyPopup}
                        user={user}
                        jobInfo={job}
                        loginRegisterParams={user ? null : applyProcessContext.loginRegisterParams}
                        openShare={applyProcessContext.showApplyModal}
                    />
                }
                <ChatWindow
                    user={user}
                    job={job}
                    handleOpenChat={handleOpenChat}
                    openChat={openChat}
                />
                <JobShowBanner
                    user={user}
                    job={job}
                    unavailable={unavailable}
                />
                <Container maxWidth='lg'>
                    {unavailable && !job.current_user_is_job_owner &&
                        <Paper elevation={4} className={classes.unavailablePaper} >
                            <Typography variant='body2' style={{ color: 'white' }}>
                                <FormattedMessage id='transl_internship.completed_banner' />
                            </Typography>
                        </Paper>
                    }
                </Container>
                <Container maxWidth='lg'>
                    {unavailable && job.current_user_is_job_owner &&
                        <PreviewBanner userValidated={user.validated} />
                    }
                </Container>
                <Hidden mdUp>
                    <Container maxWidth='lg' className={classes.mobileContainer} disableGutters>
                        <LeaderBoardAd ads={ads} type={job.type} />
                        {(!unavailable || job.current_user_is_job_owner) && <>
                            <JobShowMedia job={job} />
                            <Hidden mdDown>
                                <Box className={classes.jobPassportContainer}>
                                    <JobPassport
                                        job={job}
                                        unavailable={unavailable}
                                        user={user}
                                    />
                                </Box>
                            </Hidden>
                            <JobShowContent job={job} />
                            {((job.company_images && job.company_images.length > 0)) &&
                                <JobShowCompanyImages images={job.company_images} />
                            }
                            <Box className={classes.applyButtonBox}>
                                <ApplyButton
                                    containerClass={classes.applyButtonContainer}
                                    buttonsClass={classes.applyButton}
                                    job={job}
                                    user={user}
                                />
                            </Box>
                        </>}
                    </Container>
                </Hidden>
                <Hidden smDown>
                    {!unavailable &&
                        <Container maxWidth='lg'>
                            <LeaderBoardAd ads={ads} type={job.type} />
                        </Container>
                    }
                    {(!unavailable || job.current_user_is_job_owner) &&
                        <Container maxWidth='lg' className={classes.desktopContainer} disableGutters>
                            <Box className={classes.leftContainer}>
                                <JobPassport
                                    job={job}
                                    unavailable={unavailable}
                                    user={user}
                                />
                                <CompanyInfoCard job={job} />
                                <LetsChatCard
                                    job={job}
                                    user={user}
                                    openChat={openChat}
                                    handleOpenChat={handleOpenChat}
                                    openLoginRegisterModal={openLoginRegisterModal}
                                    unavailable={unavailable}
                                />
                            </Box>
                            <Box className={classes.midContainer}>
                                <JobShowMedia job={job} />
                                <JobShowContent job={job} />
                                {job.we_offer !== "" && job.searched_profile !== "" &&
                                    <Box className={classes.applyButtonBox}>
                                        <ApplyButton
                                            containerClass={classes.applyButtonContainer}
                                            buttonsClass={classes.applyButton}
                                            job={job}
                                            user={user}
                                        />
                                    </Box>}
                                <Box ref={verticalVideosDivRef}>
                                    <VerticalVideos
                                        type='company'
                                        cardsNumber={2}
                                        companyId={job.company_self_or_parent_id}
                                    />
                                </Box>
                                {((job.company_images && job.company_images.length > 0)) &&
                                    <JobShowCompanyImages images={job.company_images} />
                                }
                                <CompanyOtherJobs
                                    currentJobType={job.type}
                                    jobs={job?.company_jobs}
                                    companyName={job.company_name}
                                    companyUrl={job.company_url}
                                />
                            </Box>
                            <Hidden mdDown>
                                <Box className={classes.rightContainer}>
                                    <HalfPageAds ads={ads} />
                                </Box>
                            </Hidden>
                        </Container>
                    }
                </Hidden>
                <Hidden mdUp>
                    <Box className={classes.letsChatCardContainer}>
                        <LetsChatCard
                            job={job}
                            user={user}
                            openChat={openChat}
                            handleOpenChat={handleOpenChat}
                            openLoginRegisterModal={openLoginRegisterModal}
                            unavailable={unavailable}
                        />
                    </Box>
                </Hidden>
                <BottomAd ads={ads} type={job.type} placementIndex={2} />
                <RecommendedJobs job={job} />
                <NewsAndTips item={job} />

                <Container maxWidth='lg' disableGutters className={classes.ctaContainer}>
                    <CTADashboard />
                </Container>

                {job.type === 'first_job' && ads.length > 0 && <>
                    <Box className={classes.bottomAdBox}>
                        <BottomAd ads={ads} type={job.type} placementIndex={3} />
                    </Box>
                </>}
                {['internship', 'student_job'].includes(job.type) && ads.length > 0 && <>
                    <Box className={classes.bottomAdBox}>
                        <BottomAd ads={ads} type={job.type} placementIndex={3} />
                    </Box>
                </>}
                {ads.length > 0 &&
                    <Box className={classes.bottomAdBox}>
                        <BottomAd ads={ads} type={job.type} placementIndex={4} />
                    </Box>
                }
            </ApplyProcessContext.Provider>
        </>
    )
}

export default JobShow

JobShow.defaultProps = {
    job: {
        logo: {
            cloudinary_key: 'qmicd21i4d1bgkqogpop'
        },
        company: {
            banner_image_id: 'goii6xdh52hvldjmd19m'
        }
    },
    defaultCloud: 'goii6xdh52hvldjmd19m'
}
