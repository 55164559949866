import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    banner: {
        display: 'flex',
        width: '100%',
        height: 500,
        backgroundColor: theme.palette.bg.purple,
        backgroundImage: 'url(\'https://res.cloudinary.com/studentbe/image/upload/v1721138136/student-be-assets/Mask_group.png\')',
        [theme.breakpoints.down('md')]: {
            height: 400
        },
        [theme.breakpoints.down('sm')]: {
            height: 'fit-content'
        }
    },
    bannerContainer: {
        display: 'flex'
    },
    bannerText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 24,
        width: '60%',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            padding: 70,
            width: '100%',
            textAlign: 'center'
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0 10px 20px 10px',
        },
    },
    bannerTitle: {
        color: theme.palette.fg.white,
        fontSize: 60,
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            fontSize: 45
        }
    },
    bannerImage: {
        display: 'flex',
        width: '40%',
        justifyContent: 'center',
        alignItems: 'flex-end',
        [theme.breakpoints.down('md')]: {
            width: 0
        },
        '& >img': {
            height: 500
        }
    },
    titleContainer: {
        paddingBottom: '33px',
        color: theme.palette.fg.white,
        fontSize: '40px',
        lineHeight: '50px',
        textAlign: 'center',
        textTransform: 'uppercase',
        [theme.breakpoints.up('md')]: {
            paddingBottom: '30px',
            paddingLeft: '100px',
            paddingRight: '100px'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '63px',
            lineHeight: '65px'
        }
    },
    pageTitleFullWidth: {
        display: 'flex',
        alignItems: 'center',
        color: 'black',
        fontSize: 36,
        marginBottom: 12,
        fontFamily: 'GreedWide-Heavy, sans-serif',
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            fontSize: 26,
            textAlign: 'center',
            lineHeight: 1.2
        }
    },
    pageSubTitle: {
        fontSize: 18,
        width: '75%',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            textAlign: 'center',
            width: '100%'
        }
    },
    bannerCTA: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end'
    },
    button: {
        width: 220,
        fontSize: 14,
        fontWeight: 500,
        minHeight: 60,
        textTransform: 'uppercase !important',
        [theme.breakpoints.down('sm')]: {
            width: 'unset',
            minWidth: 200,
            minHeight: 40
        }
    },
    containerOverride: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: 'inherit',
        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            paddingRight: 0,
            margin: 0
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    whyUs: {
        width: '100%',
        maxHeight: '420px',
        marginTop: 120,
        padding: '0px 0px',
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'unset',
            marginTop: 60,
        }
    },
    whyUsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 42
    },
    titleSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    whyUsStats: {
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    whyUsStat: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 24,
        width: '33%',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: 12,
            width: 'unset'
        },
    },
    iconContainer: {
        width: 64,
        height: 64,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& >svg': {
            width: 36,
            height: 36,
            [theme.breakpoints.down('sm')]: {
                width: 24,
                height: 24,
            }
        },
        [theme.breakpoints.down('sm')]: {
            width: 44,
            height: 44,
        },
    },
    stat: {
        fontFamily: 'GreedWide-heavy !important',
        fontSize: 40,
        [theme.breakpoints.down('sm')]: {
            fontSize: 28,
        },
    },
    statDescription: {
        fontSize: 16,
        color: 'var(--foreground-black-60-opacity)',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            margin: '0 auto'
        },
        [theme.breakpoints.down('xs')]: {
            width: '80%',
            margin: '0 auto'
        }
    },
    whatWeOffer: {
        marginTop: 120,
        padding: '0px 0px',
        [theme.breakpoints.down('sm')]: {
            marginTop: 60,
        },
    },
    offers: {
        display: 'flex',
        flexDirection: 'column',
        gap: 60
    },
    offer: {
        display: 'flex',
        flexDirection: 'row',
        width: '80%',
        margin: '0 auto',
        gap: 60,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            gap: 20,
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    offerTitle: {
        fontWeight: 'bold',
        fontSize: 24
    },
    offerTagline: {
        fontSize: 22
    },
    offerMobileReverse: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse'
        }
    },
    offerImage: {
        width: '50%',
        objectFit: 'cover',
        borderRadius: 20,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    offerText: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 8,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    boldBlue: {
        fontWeight: 'bold',
        color: theme.palette.fg.blue,
        fontSize: 18
    },
    boldPurple: {
        fontWeight: 'bold',
        color: theme.palette.fg.purple,
        fontSize: 18
    },
    expandingText: {},
    offerExplanationTitle: {
        display: 'flex',
        alignItems: 'center',
        gap: 6,
        cursor: 'pointer'
    },
    offerExplanation: {
        color: theme.palette.fg.black60Opacity
    },
    divider: {
        margin: '20px 0'
    },
    squeezeText: {
        lineHeight: '1rem'
    },
    // FIND OUT MORE
    findOutMore: {
        marginTop: 120,
        [theme.breakpoints.down('sm')]: {
            marginTop: 60,
        },
    },
    postAJob: {
        marginTop: 120,
        [theme.breakpoints.down('sm')]: {
            marginTop: 60,
        },
    },
    // THEY TRUST US, CLIENT
    theyTrustUs: {
        marginTop: 120,
        padding: '0px 0px',
        [theme.breakpoints.down('sm')]: {
            marginTop: 60,
        },
    },
    partnerBox: {
        alignItems: 'center'
    },
    partnerImageBox: {
        width: '100%',
        '& a': {
            display: 'flex'
        }
    },
    partnerImage: {
        width: '40%',
        objectFit: 'contain',
        margin: '1rem auto',
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.05)',
            transition: '.2s'
        },
        [theme.breakpoints.up('md')]: {
            width: '65%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '70%'
        }
    },
    employerArticles: {
        marginTop: 120,
        padding: '0px 0px',
        [theme.breakpoints.down('sm')]: {
            marginTop: 60,
        },
    },
    employerArticlesTitle: {
        color: 'var(--black)',
        fontSize: 40,
        textTransform: 'uppercase',
        marginBottom: 12,
        [theme.breakpoints.down('sm')]: {
            fontSize: 22,
            lineHeight: 1.2,
            textAlign: 'center'
        }
    },
    employerArticlesSubtitle: {
        fontSize: 18
    },
    articles: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 20,
        marginTop: 30,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            '& a:nth-child(n+4)': {
                display: 'none'
            }
        }
    },
    employerArticleCTA: {
        display: 'flex',
        justifyContent: 'center'
    },
    homeStatsContainer: {
        margin: '60px auto'
    }
}))
