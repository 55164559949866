import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 28,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    packageCard: {
        maxWidth: 350,
        minWidth: 330,
        backgroundColor: 'white',
        borderRadius: 12,
        padding: 32,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 20,
        border: '1px solid var(--background-silver)',
        [theme.breakpoints.down('sm')]: {
            width: 340,
            padding: '32px 16px'
        }
    },
    CTASection: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8
    },
    cardCTATitle: {
        fontFamily: 'GreedStandard-Bold, sans-serif',
        textAlign: 'center',
        fontSize: 20
    },
    cardIcon: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: 100
    },
    iconContainer: {
        width: '64px',
        height: '64px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cardTitle: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontFamily: 'GreedWide-Heavy, sans-serif',
        color: theme.palette.fg.black,
        margin: '0 auto',
        minWidth: 170,
        maxWidth: 200,
        fontSize: 32,
        lineHeight: 1
    },
    cardPriceSelection: {
        fontWeight: 'bold'
    },
    withMinHeight: {
        minHeight: 64
    },
    uppercase: {
        textTransform: 'uppercase'
    },
    creditFeatures: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        padding: '20px 0'
    },
    creditFeature: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '0 12px'
    },
    cardButton: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    button: {
        fontSize: 14
    },
    buttonDisabled: {
        cursor: 'not-allowed',
        backgroundColor: theme.palette.fg.black40Opacity,
        '&:hover': {
            backgroundColor: theme.palette.fg.black40Opacity,
        }
    },
    squeezeText: {
        lineHeight: '1rem'
    },
    creditCostInfo: {
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        alignItems: 'center',
        margin: '0 auto'
    },
    creditSelect: {
        borderBottom: '1px solid var(--background-silver)'
    },
    overrideSelectIcon: {
        color: 'var(--black)',
        top: 8
    },
    selected: {
        fontWeight: 'bold'
    },
    placeholder: {
        color: 'var(--grey-middle)'
    },
    buyCredits: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    dialogContainer: {},
    dialogTitleRoot: {},
    dialogTitle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}))
