import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Bugsnag from '@bugsnag/js'
import { Box, Typography, Switch, TextField, IconButton } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { Autocomplete } from '@material-ui/lab'
import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import CheckboxList from 'components/Searchbar/shared/CheckboxList/CheckboxList'
import SectionTitle from 'components/SectionTitle/SectionTitle'
import Field from 'inputs/Field/Field'
import ObjectTagList from 'components/ObjectTagList/ObjectTagList'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import CollectionHandler from 'assets/CollectionHandler'
import CloudinaryHelper from 'assets/CloudinaryHelper'
import CloudinaryURLHelper from 'assets/CloudinaryURLHelper'
import IconFactory from 'icons/IconFactory/IconFactory'
import PaperFormWrapper from 'components/PaperFormWrapper/PaperFormWrapper'
import SharedTabs from 'components/SharedTabs/SharedTabs'
import Button from 'components/Button/Button'
import ArticleFormTextEditor from './components/ArticleFormTextEditor/ArticleFormTextEditor'
import UploadImageV2 from 'modals/UploadImageV2/UploadImageV2'
import { useStyles } from './newarticle_style'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import DocumentAPI from 'api/DocumentAPI'
import FeedbackSnackbar from 'components/FeedbackSnackbar/FeedbackSnackbar'

const NewArticle = (props) => {
    const { user, article, companies, companyId, action, disableFooter, disableNavbar, admin, linkedCompanies } = props
    const classes = useStyles()
    const [state, setState] = useState({
        companyId: companyId,
        title: article?.title || '',
        content: article?.content || '',
        metaDescription: article?.meta_description || '',
        language: article?.language || '',
        categoryList: article?.tags || [],
        studyDomainList: article?.study_domains || [],
        bannerImage: article?.banner_image || '',
        coverImage: article?.cover_image || '',
        ebook: article?.ebook?.cloudinary_key,
        ebookId: article?.ebook?.id,
        active: article?.active || false,
        alsoRead: article?.also_read || true,
        draft: article?.draft || false,
        aiAssistance: article?.ai_assistance || false,
        errorsList: {},
        loaded: false,
        publicationDate: article?.publication_date || '',
        jobTitleSubString: article?.job_title_sub_string || '',
        companyWriter: {},
        linkedCompanies: linkedCompanies || []
    })

    const [feedback, setFeedback] = useState({ open: false, severity: 'success', message: null })
    const closeFeedback = () => setFeedback({ ...feedback, open: false })

    const MINIMUM_TITLE_LENGTH = 20
    const SUGGESTED_MAX_TITLE_LENGTH = 70
    const MAX_TITLE_LENGTH = 90
    const MINIMUM_META_DESCR_LENGTH = 70
    const MAXIMUM_META_DESCR_LENGTH = 160

    const originUrl = window.location.search.replace('?redirect=', '')

    useEffect(() => {
        axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
        articleStudyDomainsSort()
        canSubmit()
        if (companies) {
            initCompanyWriter()
        }
        setState((prevState) => ({
            ...prevState,
            loaded: true
        }))
    }, [companies])

    const articleCategoryCollection = admin
        ? CollectionHandler.Get('articleCategoryCollection')
        : CollectionHandler.Get('limitedArticleCategoryCollection')
    let articleStudyDomainsCollection = []

    const initCompanyWriter = () => {
        setState({
            ...state,
            companyWriter: companies.find(company => company.id === state.companyId)
        })
    }

    const articleStudyDomainsSort = () => {
        const domains = CollectionHandler.Get('studyDomainCollection')
        const newDomains = []

        for (var domain in domains) {
            if (domains[domain].value !== 'event') {
                newDomains.push(
                    domains[domain]
                )
            }
        }

        articleStudyDomainsCollection = newDomains
    }

    const handleChange = (event) => {
        const { name, value } = event.target
        setState((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleChangeDate = (date) => {
        setState((prevState) => ({
            ...prevState,
            publicationDate: date
        }))
    }

    const handleBannerImageChange = (bannerImage) => {
        setState((prevState) => ({
            ...prevState,
            bannerImage: bannerImage
        }))
    }

    const handleCoverImageChange = (coverImage) => {
        setState((prevState) => ({
            ...prevState,
            coverImage: coverImage
        }))
    }

    const handleQuillChange = (value) => {
        setState((prevState) => ({
            ...prevState,
            content: value
        }))
    }

    const getActiveTags = (type) => {
        if (state[type]) {
            return state[type].map((item) => item.value)
        }
        return []
    }

    const canSubmit = () => {
        const canSubmit =
            !!state.coverImage &&
            state.title &&
            state.title.length >= MINIMUM_TITLE_LENGTH &&
            state.title.length <= MAX_TITLE_LENGTH &&
            state.content &&
            state.metaDescription &&
            state.metaDescription.length >= MINIMUM_META_DESCR_LENGTH &&
            state.metaDescription.length <= MAXIMUM_META_DESCR_LENGTH &&
            state.language &&
            getActiveTags('categoryList').length > 0

        return canSubmit
    }

    const frontValidation = () => {
        const { title, content, metaDescription, language, coverImage } = state
        const categoryList = getActiveTags('categoryList')

        const errorsList = {}
        if (title === '') {
            errorsList.title = <FormattedMessage id='landing_new_article.errors.enter_title' />
        } else if (title.length > MAX_TITLE_LENGTH) {
            errorsList.title = <FormattedMessage id='landing_new_article.max_140_char' />
        }
        if (content === '') {
            errorsList.content = <FormattedMessage id='landing_new_article.errors.enter_content' />
        }
        if (metaDescription === '') {
            errorsList.meta_description = <FormattedMessage id='landing_new_article.errors.enter_meta_data' />
        }
        if (language === '') {
            errorsList.language = <FormattedMessage id='landing_new_article.errors.enter_language' />
        }
        if (coverImage) {
            errorsList.coverImage = <FormattedMessage id='landing_new_article.errors.enter_illustration' />
        }
        if (categoryList.length === 0) {
            errorsList.categoryList = <FormattedMessage id='landing_new_article.errors.choose_category' />
        }
        setState((prevState) => ({
            ...prevState,
            errorsList
        }))
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if (canSubmit()) {
            const {
                companyId,
                title,
                content,
                metaDescription,
                language,
                bannerImage,
                coverImage,
                ebookId,
                active,
                alsoRead,
                aiAssistance,
                draft,
                publicationDate,
                jobTitleSubString,
                linkedCompanies
            } = state

            const categoryList = getActiveTags('categoryList')
            const studyDomainList = getActiveTags('studyDomainList')

            axios({
                url: action === 'edit' ? `/api/v1/articles/${article.id}` : '/api/v1/articles',
                method: action === 'edit' ? 'patch' : 'post',
                data: {
                    company_id: companyId,
                    title,
                    content,
                    meta_description: metaDescription,
                    language,
                    categories: categoryList,
                    cover_image_id: coverImage?.id,
                    banner_image_id: bannerImage?.id,
                    ebook_id: ebookId,
                    active,
                    also_read: alsoRead,
                    ai_assistance: aiAssistance,
                    draft,
                    publication_date: publicationDate,
                    admin: admin,
                    job_title_sub_string: jobTitleSubString,
                    linked_companies: linkedCompanies.map((company) => company.id),
                    study_domains: studyDomainList
                }
            })
                .then((response) => {
                    setState({ errorsList: {} })

                    if (admin) {
                        window.location.reload()
                    } else if (user && admin) {
                        window.location.href = response.data.articleUrl
                    } else if (originUrl === 'company-profile') {
                        window.location.href = response.data.companyProfileUrl
                    } else {
                        window.location.href = response.data.redirectionUrl
                    }
                    setFeedback({
                        open: true,
                        severity: 'success',
                        message: <FormattedMessage id='landing_new_article.article_posted' />
                    })
                    window.scrollTo(0, 0)
                })
                .catch((error) => {
                    if (error.response?.data.error) {
                        setState({ errorsList: error.response.data.error })
                    } else {
                        setFeedback({
                            open: true,
                            severity: 'error',
                            message: <FormattedMessage id='landing_new_article.errors.error_posting' />
                        })
                    }
                })
        } else {
            frontValidation()
            setFeedback({
                open: true,
                severity: 'error',
                message: <FormattedMessage id='landing_new_article.errors.error_posting' />
            })
            window.scrollTo(0, 0)
        }
    }

    const postUploadEbook = (uploadResult) => {
        const data = {
            cloudinary_key: uploadResult.public_id,
            format: uploadResult.format,
            name: uploadResult.original_filename,
            is_pdf: true
        }
        DocumentAPI.postDocument(data).then((response) => {
            setState({
                ebook: response.data.document_object.cloudinary_key,
                ebookId: response.data.document_object.id
            })
        }).catch((error) => {
            alert(`An error has occurred: ${error}`)
            Bugsnag.notify(error)
        })
    }

    const uploadEbook = (value) => {
        const cloudinaryWidget = CloudinaryHelper.createDocumentUploadWidget(
            { clientAllowedFormats: ['pdf'] },
            (error, result) => {
                if (!error && result?.event === 'success') {
                    postUploadEbook(result.info)
                }
            }
        )
        cloudinaryWidget.open()
    }

    const deleteEbook = () => {
        setState({
            ebook: null,
            ebookId: null
        })
    }

    const getCountColorClasName = (currentLength, minLength, maxLength) => {
        let colorClassName = {}
        if (currentLength < minLength || currentLength > maxLength) {
            colorClassName = classes.countColor
        }
        return colorClassName
    }

    const renderTitleCount = () => (
        <span className={getCountColorClasName(state.title.length, MINIMUM_TITLE_LENGTH, SUGGESTED_MAX_TITLE_LENGTH)}>
            <FormattedMessage id='landing_new_article.title_instructions' />{state.title.length}
        </span>
    )

    const renderDescriptionCount = () => (
        <span className={getCountColorClasName(state.metaDescription.length, MINIMUM_META_DESCR_LENGTH, MAXIMUM_META_DESCR_LENGTH)}>
            <FormattedMessage id='landing_new_article.about_article_summary' />{state.metaDescription.length}
        </span>
    )

    const renderContentCount = () => (
        <span>
            <FormattedMessage id='landing_new_article.content_instructions' />{state.content.split(' ').length}
        </span>
    )

    return state.loaded ? (
        <PageWrapper user={user} disableFooter={disableFooter} disableFooterLists disableNavbar={disableNavbar}>
            <SharedTabs user={user} currentTab='users/articles' hidden={admin} />
            <FeedbackSnackbar
                open={feedback.open}
                message={feedback.message}
                severity={feedback.severity}
                closeFeedback={closeFeedback}
            />
            <PaperFormWrapper hidden={admin}>
                <Box mb={6}>
                    <SectionTitle className={classes.sectionTitle} step='1' title={<FormattedMessage id='landing_new_article.article_title' />} />
                    <Typography variant='body2'>{renderTitleCount()}</Typography>
                    <Box mb={2} mt={1}>
                        <FormattedMessage id='landing_new_article.max_title_char'>
                            {(placeholder) => (
                                <TextField
                                    fullWidth
                                    required
                                    value={state.title}
                                    name='title'
                                    placeholder={placeholder}
                                    onChange={handleChange}
                                    autoComplete='on'
                                    error={!!state.errorsList.title}
                                />
                            )}
                        </FormattedMessage>
                    </Box>
                </Box>
                <Box my={6}>
                    <SectionTitle className={classes.sectionTitle} step='2' title={<FormattedMessage id='landing_new_article.illustrative_image' />} />
                    <Typography variant='body2'>
                        <FormattedMessage id='landing_new_article.about_article' />
                    </Typography>
                    <Box my={2}>
                        <UploadImageV2
                            isLarge
                            image={state.coverImage}
                            setImage={handleCoverImageChange}
                            error={!!state.errorsList.coverImage}
                        />
                    </Box>
                    <Box my={6}>
                        <SectionTitle title={<FormattedMessage id='landing_new_article.cover_image' />} />
                        <Typography variant='body2'>
                            <FormattedMessage id='landing_new_article.about_cover_image' />
                        </Typography>
                        <Box my={2}>
                            <UploadImageV2
                                isLarge
                                image={state.bannerImage}
                                setImage={handleBannerImageChange}
                                error={!!state.errorsList.bannerImage}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box my={6}>
                    <SectionTitle className={classes.sectionTitle} step='3' title={<FormattedMessage id='landing_new_article.body_content' />} />
                    <Typography variant='body2'>{renderContentCount()}</Typography>
                    <Box className={classes.articleEditorText}>
                        <ArticleFormTextEditor
                            isAdmin={admin}
                            value={state.content}
                            onChange={handleQuillChange}
                        />
                        {state.errorsList.content && (
                            <p className='error'>{state.errorsList.content}</p>
                        )}
                    </Box>
                </Box>
                <Box my={6}>
                    <SectionTitle className={classes.sectionTitle} step='4' title={<FormattedMessage id='landing_new_article.article_summary' />} />
                    <Typography variant='body2'>{renderDescriptionCount()}</Typography>
                    <FormattedMessage id='landing_new_article.max_180_char'>
                        {(placeholder) => (
                            <Field
                                type='text'
                                name='metaDescription'
                                placeholder={placeholder}
                                value={state.metaDescription}
                                handleChange={handleChange}
                                error={!!state.errorsList.meta_description}
                            />
                        )}
                    </FormattedMessage>
                </Box>
                <Box my={6}>
                    <SectionTitle className={classes.sectionTitle} step='5' title={<FormattedMessage id='landing_new_article.language' />} />
                    <Typography variant='body2'>
                        <FormattedMessage id='landing_new_article.about_language' />
                    </Typography>
                    <Field
                        name='language'
                        type='radio'
                        required
                        options={[
                            { name: 'Nederlands', value: 'nl' },
                            { name: 'Français', value: 'fr' },
                            { name: 'English', value: 'en' }
                        ]}
                        direction='column'
                        size='1'
                        value={state.language}
                        handleChange={handleChange}
                        error={!!state.errorsList.language}
                    />
                </Box>
                <Box my={6}>
                    <SectionTitle className={classes.sectionTitle} step='6' title={<FormattedMessage id='landing_new_article.tags' />} />
                    <Typography variant='body2'>
                        <FormattedMessage id='landing_new_article.about_tags' />
                    </Typography>
                    <Box mt={2} mb={5}>
                        <CheckboxList
                            type='categories'
                            collection={articleCategoryCollection}
                            selectedItems={state.categoryList}
                            setSelectedItems={(selectedItems) => { setState({ ...state, categoryList: selectedItems }) }}
                            width={400}
                            // canSubmit={canSubmit}
                            // error={!!state.errorsList.categoryList}
                        />
                    </Box>
                </Box>
                {admin && (
                    <>
                        <Box my={6}>
                            <SectionTitle className={classes.sectionTitle} step='7' title='Active' />
                            <Typography variant='body2'>
                                <FormattedMessage id='landing_new_article.about_active' />
                            </Typography>
                            <Switch
                                color='secondary'
                                checked={state.active}
                                value={state.active}
                                name='active'
                                onChange={() => setState((prevState) => ({ ...prevState, active: !prevState.active }))}
                            />
                        </Box>
                        <Box my={6}>
                            <SectionTitle className={classes.sectionTitle} step='8' title='Also Read' />
                            <Typography variant='body2'>
                                <FormattedMessage id='landing_new_article.about_also_read' />
                            </Typography>
                            <Switch
                                color='secondary'
                                checked={state.alsoRead}
                                value={state.alsoRead}
                                name='alsoRead'
                                onChange={() => setState((prevState) => ({ ...prevState, alsoRead: !prevState.alsoRead }))}
                            />
                        </Box>
                        <Box my={6}>
                            <SectionTitle className={classes.sectionTitle} step='9' title='Draft' />
                            <Typography variant='body2'>
                                <FormattedMessage id='landing_new_article.about_draft' />
                            </Typography>
                            <Switch
                                color='secondary'
                                checked={state.draft}
                                value={state.draft}
                                name='draft'
                                onChange={() => setState((prevState) => ({ ...prevState, draft: !prevState.draft }))}
                            />
                        </Box>
                        <Box my={6}>
                            <SectionTitle className={classes.sectionTitle} step='10' title='AI assistance' />
                            <Typography variant='body2'>
                                <FormattedMessage id='landing_new_article.about_ai_assistance' />
                            </Typography>
                            <Switch
                                color='secondary'
                                checked={state.aiAssistance}
                                value={state.aiAssistance}
                                name='aiAssistance'
                                onChange={() => setState((prevState) => ({ ...prevState, aiAssistance: !prevState.aiAssistance }))}
                            />
                        </Box>
                        <Box my={6}>
                            <SectionTitle className={classes.sectionTitle} step='11' title='Company Writer' />
                            <Typography variant='body2'>
                                <FormattedMessage id='landing_new_article.about_company_writer' />
                            </Typography>
                            <Autocomplete
                                disablePortal
                                value={state.companyWriter}
                                onChange={(_, value) => setState((prevState) => ({
                                    ...prevState,
                                    companyWriter: value,
                                    companyId: value.id
                                }))}
                                id='company-writer-field'
                                options={companies}
                                getOptionLabel={(option) => option.name}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label='Company' />}
                            />
                        </Box>
                        <Box my={6}>
                            <SectionTitle className={classes.sectionTitle} step='12' title='Publication date' />
                            <Typography variant='body2'>
                                <FormattedMessage id='landing_new_article.about_publication_date' />
                            </Typography>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    value={state.publicationDate}
                                    keyboardIcon={<IconFactory icon='date' />}
                                    required
                                    error={false}
                                    helperText={null}
                                    className={classes.date}
                                    format='dd/MM/yyyy'
                                    onChange={handleChangeDate}
                                    KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                    InputProps={{ classes: { input: classes.inputBase } }}
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                        <Box my={6}>
                            <SectionTitle className={classes.sectionTitle} step='13' title='Linked companies' />
                            <Typography variant='body2'>
                                <FormattedMessage id='landing_new_article.about_linked_companies' />
                            </Typography>
                            <Autocomplete
                                disablePortal
                                id='linked-companies-field'
                                value={state.linkedCompanies}
                                multiple={true}
                                options={companies}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, value) => setState((prevState) => ({ ...prevState, linkedCompanies: value }))}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label='Linked companies' />}
                            />
                        </Box>
                        <Box my={6}>
                            <SectionTitle className={classes.sectionTitle} step='14' title='Linked jobs' />
                            <Typography variant='body2'>
                                <FormattedMessage id='landing_new_article.about_linked_jobs' />
                            </Typography>
                            <Field
                                type='input'
                                name='jobTitleSubString'
                                value={state.jobTitleSubString}
                                handleChange={handleChange}
                            />
                        </Box>
                        <Box mt={2} mb={5}>
                            <SectionTitle className={classes.sectionTitle} step='15' title='Study domains' />
                            <ObjectTagList
                                enableSelectAll
                                value={state.studyDomainList}
                                collection={articleStudyDomainsCollection}
                                name='study_domains'
                                onRef={(ref) => (state.studyDomainList = ref)}
                                canSubmit={canSubmit}
                                error={!!state.errorsList.studyDomainList}
                                disabledSelectAll
                            />
                        </Box>
                        <Box mt={2} mb={5}>
                            <SectionTitle className={classes.sectionTitle} step='16' title='Link an ebook' />
                            {state.ebook ? (
                                <>
                                    <IconButton onClick={deleteEbook}>
                                        <IconFactory icon='delete' />
                                    </IconButton>
                                    <RouteMapLink
                                        redirectionUrl={CloudinaryURLHelper.getDocumentUrl(state.ebook)}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        {CloudinaryURLHelper.getDocumentUrl(state.ebook)}
                                    </RouteMapLink>
                                </>
                            ) : (
                                <Button
                                    onClick={uploadEbook}
                                    color='secondary'
                                >
                                            Upload Ebook
                                </Button>
                            )}
                        </Box>
                    </>
                )}
                <Box className={classes.articleSubmitButton}>
                    <Button
                        onClick={handleSubmit}
                        color='secondary'
                    >
                        {action === 'edit'
                            ? <FormattedMessage id='landing_new_article.save' />
                            : <FormattedMessage id='landing_new_article.publish' />}
                    </Button>
                </Box>
            </PaperFormWrapper>
        </PageWrapper>
    ) : null
}

export default NewArticle
