export const DEFAULT_TAB = 'overview'

export const getTabFromUrl = (tabs) => {
    const currentPath = window.location.pathname
    const lastSegment = currentPath.split('/').pop()
    if (tabs.includes(lastSegment)) return lastSegment
}

export const updateURL = (tab, tabs) => {
    const currentUrl = new URL(window.location.href)
    const currentPath = currentUrl.pathname
    const queryParams = new URLSearchParams(currentUrl.search)

    let newPath
    const lastSegment = currentPath.split('/').pop()
    if (tab === DEFAULT_TAB) {
        newPath = tabs.includes(lastSegment) ? currentPath.split('/').slice(0, -1).join('/') : currentPath
    } else if (tabs.includes(lastSegment)) {
        newPath = `${currentPath.split('/').slice(0, -1).join('/')}/${tab}`
    } else {
        newPath = `${currentPath}/${tab}`
    }
    newPath = newPath.replace(/\/\//g, '/')
    if (queryParams.toString()) {
        newPath = `${newPath}?${queryParams.toString()}`
    }

    history.pushState(null, '', newPath)
}
