import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import { Box, Container, Hidden, Typography } from '@material-ui/core'
import { Image, Transformation } from 'cloudinary-react'
import isEmpty from 'lodash.isempty'

import { useStyles } from './CompanyPage_style'
import ArticleCardV2 from 'cards/ArticleCard/ArticleCardV2'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import CompanyProfileHelmet from 'helmets/CompanyProfileHelmet'
import { optimiseSizeForMobile, EXTRA_LARGE_SIZE, SMALLER_SIZE } from 'assets/CloudinaryURLHelper'
import RouteMap from 'assets/RouteMap.js'
import URLHandler from 'assets/URLHandler'
import Sanitizer from 'assets/Sanitizer'
import VideoAPI from 'api/VideoAPI'
import CompanyPageImages from './CompanyPageImages/CompanyPageImages'
import CompanyRecentJobs from 'shared/components/CompanyRecentJobs/CompanyRecentJobs'
import CollectionHandler from 'assets/CollectionHandler.js'
import CompanyTabs from './CompanyTabs/CompanyTabs'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import Button from 'shared/components/Button/Button'
import SharedTabs from 'components/SharedTabs/SharedTabs'
import ShareAction from 'components/ShareAction/ShareAction'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import CompanyKeyStats from './CompanyKeyStats/CompanyKeyStats'
import CompanyJobs from './CompanyJobs/CompanyJobs'
import { DEFAULT_TAB, getTabFromUrl, updateURL } from './CompanyPageHelper'
import HorizontalCardsSlider from 'shared/components/HorizontalCardsSlider/HorizontalCardsSlider'
import ResponsiveVideo from 'shared/components/ResponsiveVideo/ResponsiveVideo'
import ExpandingText from 'shared/components/ExpandingText/ExpandingText'
import CompanyEditBanner from './CompanyEditBanner/CompanyEditBanner'
import translations from 'assets/i18n/translations.json'
import SimilarCompanies from 'shared/components/SimilarCompanies/SimilarCompanies'

const CompanyPage = (props) => {
    const { user, page, company, userIsOwner, articles, jobs, jobsCount, editPage } = props
    const locale = URLHandler.GetLocaleFromUrl()
    const [tabs] = useState(['overview', 'jobs'])
    const [selectedTab, setSelectedTab] = useState(getTabFromUrl(tabs) || DEFAULT_TAB)
    const [selectedArticles] = useState(articles.slice(0, 2))
    const [videos, setVideos] = useState([])
    const [companyImages, setCompanyImages] = useState(company.gallery_images)
    const [featuredCharacteristics, setFeaturedCharacteristics] = useState([])
    const classes = useStyles({ noBanner: !company.banner_image_cloudinary_key, editPage: editPage })
    const companyDescription = company['description_' + RouteMap.GetLocaleFromUrl()] || company.description

    useEffect(() => {
        let info = company.featured_characteristics.map((characteristic) => {
            if (company.extra_info[characteristic]) return { [characteristic]: company.extra_info[characteristic] }
        })
        info = info.filter(item => item)
        setFeaturedCharacteristics(info)
    }, [])

    useEffect(() => {
        updateURL(selectedTab, tabs)
    }, [selectedTab])

    const handleTabSelection = (clickedTab) => {
        if (clickedTab === selectedTab) return

        setSelectedTab(clickedTab)
    }

    useEffect(() => {
        if (company.main_show_video && company.main_video_url) {
            const youtubeId = URLHandler.getYouTubeVideoID(company.main_video_url)
            const youtubeImageUrl = URLHandler.youtubeImageUrlBuilder(youtubeId)
            if (!youtubeImageUrl) return

            const youtubeImageSpecs = {
                type: 'video',
                format: 'regular',
                provider: 'youtube',
                src: youtubeImageUrl,
                sourceId: youtubeId,
                width: 800,
                height: 475
            }
            setCompanyImages([youtubeImageSpecs, ...companyImages])
        } else if (!company.main_show_video && company.main_image_cloudinary_key) {
            const imageSpecs = {
                type: 'image',
                cloudinary_key: company.main_image_cloudinary_key,
            }
            setCompanyImages([imageSpecs, ...companyImages])
        }
    }, [])

    useEffect(() => {
        getVideos()
    }, [])

    useEffect(() => {
        if (!isEmpty(videos)) {
            const formattedVideos = videos.map(video => {
                const youtubeImageUrl = URLHandler.youtubeImageUrlBuilder(video.youtube_video_id)
                return {
                    type: 'video',
                    format: 'shorts',
                    provider: 'youtube',
                    src: youtubeImageUrl,
                    sourceId: video.youtube_video_id,
                    width: 250,
                    height: 480
                }
            })
            setCompanyImages([...companyImages, ...formattedVideos])
        }
    }, [videos])

    const getVideos = () => {
        const data = {
            type: 'company',
            company_id: company.id
        }

        VideoAPI.GetVideos(data).then((response) => {
            const fetchedVideos = response.data.splice(0, 10)
            setVideos(fetchedVideos)
        })
    }

    return (
        <PageWrapper user={user} page={page} disableFooterLists>
            <CompanyProfileHelmet company={company} logoCloudinaryKey={company.logo_cloudinary_key} />
            {editPage &&
                <SharedTabs user={user} currentTab='users/company/edit' />
            }
            {editPage &&
                <CompanyEditBanner
                    user={user}
                    company={company}
                />
            }
            {(company.banner_image_cloudinary_key || editPage) &&
                <Box className={classes.bannerContainer}>
                    <Box className={classes.shareButton}>
                        <ShareAction
                            infos={{
                                title: company.name,
                                url: RouteMap.Show('company', company.url),
                                type: 'company'
                            }}
                        />
                    </Box>
                    <Image
                        alt={editPage ? translations[locale]['company_page.add_banner_image'] : `${company.name} banner`}
                        cloudName="studentbe"
                        publicId={company.banner_image_cloudinary_key}
                        className={editPage ? classes.addBannerCTA : ''}
                    >
                        <Transformation flags="progressive" fetchFormat="auto" width={optimiseSizeForMobile(EXTRA_LARGE_SIZE)} quality="auto:best" crop="fill" />
                    </Image>
                </Box>
            }
            <Box className={classes.pageContent}>
                <Container maxWidth='lg'>
                    <Box className={classes.companyInfo}>
                        <Box className={classes.logoBox}>
                            <Image
                                className={classes.logo}
                                cloudName="studentbe"
                                publicId={company.logo_cloudinary_key ? company.logo_cloudinary_key : 'default-company-logo-black'}
                                alt={`${company.logo_cloudinary_key} logo`}
                            >
                                <Transformation flags="progressive" fetchFormat="auto" width={SMALLER_SIZE} quality="auto:best" crop="scale" />
                            </Image>
                        </Box>
                        <Box className={classes.companyInfoText}>
                            <Box className={classes.companyNameAndCTA}>
                                <Typography component='h1' variant='h1' className={classes.companyName}>
                                    {company.name}
                                </Typography>
                                {userIsOwner &&
                                    <RouteMapLink page='users/company_profiles/edit' className={classes.editPageButton}>
                                        <Button variant='cta-secondary' size='small'>
                                            <FormattedMessage id="company_profile.company_profile_banner.modify_page_button" />
                                        </Button>
                                    </RouteMapLink>
                                }
                            </Box>
                            <Box className={classes.companyDetailsFlex}>
                                <Box className={classes.companyDetails}>
                                    <IconFactory icon='location-pin' fontSize='14px' />
                                    <Typography component='span'>{company.address.city}</Typography>
                                </Box>
                                <Box className={classes.companyDetails}>
                                    <IconFactory icon='attachment' fontSize='14px' />
                                    <Typography component='span'>{CollectionHandler.Translate('sectorCollection', company.sector)}</Typography>
                                </Box>
                                {company.impact &&
                                    <Box className={classes.companyDetails}>
                                        <IconFactory icon='globe-europe' fontSize='14px' />
                                        <Typography component='span'>
                                            <FormattedMessage id='company_page.info.company_with_impact' />
                                        </Typography>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Box>
                    <Box const className={classes.companyBodyContainer}>
                        <Box className={classes.companyBodyLeftSide}>
                            <Box>
                                <Typography variant='h1' component='h2' className={classes.companyTitle}>
                                    <FormattedMessage id='job_show.company_info_card.title_prefix' /> {company.name}
                                </Typography>
                                <Hidden mdUp><ExpandingText text={companyDescription} /></Hidden>
                                <Hidden smDown>
                                    <Typography
                                        component='p'
                                        className={classes.companyDescription}
                                        dangerouslySetInnerHTML={{ __html: Sanitizer.SanitizeHtmlString(companyDescription || '') }}
                                    />
                                </Hidden>
                            </Box>
                            {!isEmpty(selectedArticles) &&
                                <Hidden smDown>
                                    <Box className={classes.companyArticlesContainer}>
                                        <Typography variant='h1' component='h2' className={classes.companyArticlesTitle}>
                                            <FormattedMessage id='job_show.news_and_tips.title' />
                                        </Typography>
                                        {selectedArticles.map(article =>
                                            <ArticleCardV2
                                                article={article}
                                                id={'articlearticle_' + article.id}
                                                key={'articlearticle_' + article.id}
                                            />
                                        )}
                                    </Box>
                                </Hidden>
                            }
                        </Box>
                        <Box className={classes.companyBodyRightSide}>
                            <CompanyTabs
                                tabs={tabs}
                                selectedTab={selectedTab}
                                setTab={handleTabSelection}
                                jobsCount={jobsCount}
                                userIsOwner={userIsOwner}
                            />
                            {selectedTab === 'overview' && <>
                                <Box className={classes.companyBodyOverview}>
                                    <Box className={classes.companyBodyOverviewCenter}>
                                        {!isEmpty(videos)
                                            ? <Container maxWidth='lg' disableGutters>
                                                <HorizontalCardsSlider shift={500}>
                                                    {videos.map((video, index) => {
                                                        return (
                                                            <Box className={classes.videoBox} key={index}>
                                                                <ResponsiveVideo
                                                                    video={{ url: video.url, width: 250, height: 480 }}
                                                                />
                                                            </Box>
                                                        )
                                                    })}
                                                </HorizontalCardsSlider>
                                            </Container>
                                            : <CompanyPageImages images={companyImages} />
                                        }
                                        {!isEmpty(featuredCharacteristics) &&
                                            <Hidden xsDown>
                                                <Box my={1}>
                                                    <CompanyKeyStats stats={featuredCharacteristics} />
                                                </Box>
                                            </Hidden>
                                        }
                                    </Box>
                                    <Box className={classes.companyBodyOverviewSide}>
                                        {!isEmpty(jobs) &&
                                            <CompanyRecentJobs
                                                jobs={jobs}
                                                handleClick={() => setSelectedTab('jobs')}
                                            />
                                        }
                                    </Box>
                                </Box>
                                {!isEmpty(videos) &&
                                    <Box className={classes.companyBodyOverviewImages}>
                                        <CompanyPageImages images={companyImages} />
                                    </Box>
                                }
                            </>}
                            {selectedTab === 'jobs' && <>
                                <CompanyJobs companyId={company.id} jobs={jobs} totalJobsCount={jobsCount} />
                            </>}
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Box>
                <SimilarCompanies company={company} />
            </Box>
        </PageWrapper>
    )
}

export default CompanyPage
