import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    homeSearchContainer: {
        background: 'var(--foreground-purple)',
        borderRadius: 20,
        padding: '28px 64px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexDirection: 'column',
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            padding: '32px 12px'
        }
    },
    searchContainerMinimal: {
        background: 'none',
        padding: 0
    },
    title: {
        fontSize: 16,
        color: 'white',
        textTransform: 'uppercase'
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 20,
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            justifyContent: 'center'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    inputContainerMinimal: {
        gap: 12
    },
    inputFieldsContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 12,
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '100%'
        },
        [theme.breakpoints.only('sm')]: {
            width: 300
        }
    },
    buttonContainer: {
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    svg: {
        width: '2461.761px',
        height: '321.102px',
        transform: 'rotate(-2deg)',
        position: 'absolute',
        right: '-983.468px',
        top: '-132px',
        strokeWidth: '44px',
        stroke: '#FB625D'
    },
    mainTitle: {
        marginBottom: 32,
        fontFamily: 'GreedWide-Bold',
        fontSize: 40,
        textAlign: 'center',
        [theme.breakpoints.only('sm')]: {
            fontSize: 24
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 20
        }
    },
    formField: {
        display: 'contents'
    },
    inputField: {
        cursor: 'pointer',
        width: 150,
        height: 50,
        backgroundColor: 'var(--beige)',
        paddingRight: 8,
        paddingLeft: 16,
        borderRadius: 8,
        '& .MuiInputBase-input': {
            padding: '0 !important'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    validateBtn: {
        width: 150,
        height: 50,
        padding: 12,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    textBold: {
        whiteSpace: 'nowrap'
    },
    iconBox: {
        padding: '0 8px'
    },
    filter: {
        height: '56px !important',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    in: {
        color: 'white'
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
}))
