/* eslint-disable react/prop-types */
import React from 'react'
import ReactOnRails from 'react-on-rails'
import axios from 'axios'
import fileDownload from 'js-file-download'

import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import SharedTabs from 'components/SharedTabs/SharedTabs'
import Searchbar from 'components/Searchbar/Searchbar'
import ShareAction from 'components/ShareAction/ShareAction'
import ListContainer from 'containers/ListContainer/ListContainer'
import PopupFactory from 'modals/Popup/PopupFactory'
import MessageAllModal from 'shared/modals/MessageAllModal/MessageAllModal'
import RouteMap from 'assets/RouteMap'
import Button from 'components/Button/Button'
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox'
import Paper from 'components/Paper/Paper'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'
import CircularProgress from '@material-ui/core/CircularProgress'
import Hidden from '@material-ui/core/Hidden'
import IconFactory from 'icons/IconFactory/IconFactory'
import SortByDropdown from 'inputs/SortByDropdown/SortByDropdown'
import { withStyles } from '@material-ui/core/styles'
import { useStyles } from './JobResults_css'
import { FormattedMessage } from 'react-intl'
import CompanyJobAPI from 'api/CompanyJobAPI'
import JobResultAPI from 'api/JobResultAPI'

class JobResults extends React.Component {
    constructor (props) {
        super(props)
        // note: 1 is 'asc'/up arrow, 0 is 'desc'/ down arrow
        this.state = {
            checkedAll: false,
            loading: false,
            items: this.props.profiles,
            resultCount: 0,
            page: 0,
            defaultItems: this.props.profiles,
            sortByKey: 'applied_date',
            orderDirection: 0,
            showPopupBoost: false,
            showPopupDeleteCandidacy: false,
            showPopupMessage: false,
            answersCheckbox: {},
            active: this.props.job.active,
            checkedItems: {},
            filters: {
                page: 1,
                name: '',
                legalAge: false,
                fieldOfStudies: false,
                studyLevel: false,
                answersCheckbox: []
            }
        }
        this.listCardsRef = []
        this.listCardsRef.push(React.createRef())
        this.checkAllRef = React.createRef()
        this.setFilters = this.setFilters.bind(this)
        this.setSortBy = this.setSortBy.bind(this)
        this.filterResults = this.filterResults.bind(this)
        this.handleFilterChange = this.handleFilterChange.bind(this)
        this.handleChangeCheckboxAnswers = this.handleChangeCheckboxAnswers.bind(this)
        this.deleteOffer = this.deleteOffer.bind(this)
        this.deleteCandidacy = this.deleteCandidacy.bind(this)
        this.toggleOffer = this.toggleOffer.bind(this)
        this.checkItem = this.checkItem.bind(this)
        this.checkAll = this.checkAll.bind(this)
        this.unCheckAll = this.unCheckAll.bind(this)
        this.openPopupMessage = this.openPopupMessage.bind(this)
        this.closePopupMessage = this.closePopupMessage.bind(this)
        this.setPage = this.setPage.bind(this)
        this.createPageBubble = this.createPageBubble.bind(this)
        this.createPageNavigation = this.createPageNavigation.bind(this)

        axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    }

    openPopupMessage () {
        this.setState({ showPopupMessage: true })
    }

    closePopupMessage () {
        this.setState({ showPopupMessage: false })
    }

    // Function used to check one element a put the user info in state
    checkItem (ref, item, checked) {
        const newCheckedItems = this.state.checkedItems
        if (item[this.props.idKey] in newCheckedItems && !checked) {
            delete newCheckedItems[item[this.props.idKey]]
            // this.changeCheckAll()
        } else {
            newCheckedItems[item[this.props.idKey]] = item
        }
        this.setState({ checkedItems: newCheckedItems })
    }

    // Function to check all the element from the ListContainer
    checkAll (check) {
        this.listCardsRef.forEach(function (listRef) {
            if (listRef.current) {
                listRef.current.listCardsRef.forEach(function (elem) {
                    if (elem.current) {
                        elem.current.setCheck(check)
                    }
                })
            }
        })
    }

    unCheckAll () {
        this.checkAllRef.current.setCheck(false)
        this.setState({ checkedItems: {} })
    }

    handleChangeCheckboxAnswers (event) {
        const { name } = event.target
        var question = name.split(',')[0]
        var answer = name.split(',')[1]

        var answersCheckbox = this.state.answersCheckbox

        if (question in answersCheckbox) {
            if (answer in answersCheckbox[question]) {
                delete answersCheckbox[question][answer]
                if (Object.keys(answersCheckbox[question]).length === 0) {
                    delete answersCheckbox[question]
                }
            } else {
                answersCheckbox[question][answer] = answer.split('-')[1]
            }
        } else {
            var newHash = {}
            newHash[answer] = answer.split('-')[1]
            answersCheckbox[question] = newHash
        }

        var newFilter = []
        Object.keys(answersCheckbox).map((question) => {
            var questions = []
            Object.keys(answersCheckbox[question]).map((answer) => {
                questions.push(answer.split('-')[1])
            })
            var questionHash = {}
            questionHash[question.split('-')[1]] = questions
            newFilter.push(questionHash)
        })

        var filters = this.state.filters
        filters.answersCheckbox = newFilter
        this.setState(
            {
                page: 0,
                filters: filters,
                answersCheckbox: answersCheckbox
            }, () => {
                this.filterResults()
            })
    }

    setSortBy (key) {
        if (this.state.sortByKey !== key) {
            let orderDirectionTemp = 1
            if (key === 'applied_date') { orderDirectionTemp = 0 }
            this.setState({
                sortByKey: key,
                orderDirection: orderDirectionTemp
            }, () => {
                this.filterResults()
            })
        } else {
            this.setState({
                orderDirection: this.state.orderDirection === 1 ? 0 : 1
            }, () => {
                this.filterResults()
            })
        }
    }

    setFilters (filters) {
        var stateFilters = this.state.filters
        var newfilters = { ...stateFilters, ...filters }
        this.setState({ filters: newfilters })
    }

    filterResults () {
        if (this.state.loading === true) { return null }
        this.setState({ loading: true }, () => {
            axios({
                url: '/api/v1/users/job_results/filter',
                method: 'post',
                data: {
                    job_id: this.props.jobId,
                    job_type: this.props.jobType,
                    filters: {
                        page: this.state.page,
                        name: this.state.filters.name,
                        study_domain: this.state.filters.fieldOfStudies.map(item => item.value),
                        study_level: this.state.filters.studyLevel.map(item => item.value),
                        recruitment_statuses: this.state.filters.recruitmentStatuses.map(item => item.value),
                        eighteen_plus: this.state.filters.legalAge,
                        answers_check_box: this.state.filters.answersCheckbox
                    },
                    order_by: this.state.sortByKey,
                    order_direction: this.state.orderDirection === 0 ? 'desc' : 'asc'
                }
            }).then(response => {
                this.setState({
                    loading: false,
                    items: response.data.profiles,
                    containsHeadhunted: response.data.contains_headhunted,
                    resultCount: response.data.result_count,
                    totalCount: response.data.total_count
                })
            }).catch((error) => {
                console.log(error)
            })
        })
    }

    downloadExport () {
        this.setState({ loading: true })
        const data = {
            job_id: this.props.jobId,
            job_type: this.props.jobType
        }
        JobResultAPI.ExportSelection(data).then(response => {
            this.setState({ loading: false })
            const excelFileName = 'Student.be - Export Candidacies.xlsx'
            fileDownload(response.data, excelFileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        })
    }

    handleFilterChange (filters) {
        filters.answersCheckbox = this.state.filters.answersCheckbox
        this.setState(
            {
                page: 0,
                filters: filters
            }, () => {
                this.filterResults()
            })
    }

    renderPopup (popupType) {
        this.setState({
            [popupType]: !this.state[popupType]
        })
    }

    deleteOffer (deleteInfos) {
        CompanyJobAPI.removeCheckedJobs({
            delete_reason: deleteInfos,
            items: [{ id: this.props.job.id, type: this.props.jobType }]
        }).then((response) => {
            window.location.href = RouteMap.Page('users/company_jobs')
        }, (error) => {
            console.log('Error deleting offer :', error)
        })
    }

    deleteCandidacy () {
        var candidatsToDelete
        if (Object.keys(this.state.checkedItems).length > 0) {
            candidatsToDelete = Object.keys(this.state.checkedItems)
        } else {
            candidatsToDelete = [this.state.showPopupDeleteCandidacy]
        }

        axios.all(candidatsToDelete.map((candidatID) => {
            return axios.delete(`/api/v1/users/candidacies?id=${candidatID}`)
        })).then(() => {
            window.location.reload()
        }, (error) => {
            console.log('Error deleting candidacy for studenet :', error)
        })
    }

    toggleOffer () {
        CompanyJobAPI.activateJob({
            update_active: true,
            active: !this.props.job.active,
            type: this.props.jobType,
            id: this.props.jobId
        }).then((response) => {
            this.setState({ active: !this.state.active })
        }, (error) => {
            console.log('Error toggling offer : ', error)
        })
    }

    renderQuestionsFilter () {
        return this.props.jobQuestions.map((question, index) => {
            return (
                <Box key={index} style={{ margin: '20px 0' }}>
                    <Box style={{ display: 'flex', marginBottom: 10 }}>
                        <Typography
                            variant='body2'
                            component='span'
                            style={{ minWidth: 25 }}
                        >
                            {(index + 1) + ' : '}
                        </Typography>
                        <Typography
                            variant='h4'
                            component='h3'
                            color='primary'
                        >
                            {question.name}
                        </Typography>
                    </Box>
                    <Box style={{ marginLeft: 25, display: 'flex', flexDirection: 'column', gap: 12 }}>
                        {
                            question.answers.map((answer, index2) => {
                                return (
                                    <Box key={index2} style={{ display: 'flex', alignItems: 'center' }}>
                                        <CustomCheckbox
                                            name={'question-' + question.id + ',answer-' + answer.id}
                                            onChange={this.handleChangeCheckboxAnswers}
                                            style={{ fontSize: 10 }}
                                        />
                                        <Typography
                                            variant='body2'
                                            style={{ marginLeft: 15, fontSize: 14 }}
                                        >
                                            {answer.name}
                                        </Typography>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                    {this.props.jobQuestions.length !== index + 1 &&
                        <Divider orientation='horizontal' style={{ width: '100%', marginTop: 20 }}/>
                    }
                </Box>
            )
        })
    }

    setPage (page) {
        if (page > -1 && page > -1) {
            this.setState({
                page: page
            }, () => {
                this.filterResults()
                window.scrollTo(0, 0)
            })
        }
    }

    createPageBubble () {
        const { classes } = this.props
        const res = []
        let offset = 1
        const pagesNumber = Math.ceil(this.state.resultCount / 20 - 1)
        if (this.state.page !== 0) {
            offset -= 1
        }
        if (this.state.page === pagesNumber && pagesNumber > 1) {
            offset -= 1
        }
        for (let i = 0; i < Math.min(3, pagesNumber + 1); i++) {
            const page = this.state.page + offset + i
            // res.push(<p className={'page-bubble' + (i + offset == 1 ? ' current' : '')} key={'page-' + page} onClick={() => this.setPage(page - 1)}>{page}</p>)
            var addStyle = (i + offset === 1) ? classes.active : classes.nonActive
            var style = `${classes.pageBubble} ${addStyle}`
            res.push(
                <Typography
                    variant='body1'
                    component='div'
                    className={style}
                    key={'page-' + page}
                    onClick={() => this.setPage(page - 1)}
                >
                    {page}
                </Typography>
            )
        }
        return res
    }

    createPageNavigation () {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', padding: '20px 0' }}>
                {this.createPageBubble()}
            </Box>
        )
    }

    shareInfos () {
        return (
            {
                url: this.props.url,
                type: this.props.jobType
            }
        )
    }

    render () {
        const { classes } = this.props

        return (
            <>
                <PageWrapper user={this.props.user} disableFooterLists>
                    <SharedTabs user={this.props.user} currentTab='users/company_jobs' />
                    <div className={'job-results-container ' + this.props.jobType.replace('_', '-')}>
                        {this.state.showPopupBoost &&
                        <PopupFactory
                            type="boost-offer"
                            popupExit={() => this.renderPopup('showPopupBoost')}
                        />
                        }
                        {this.state.showPopupDeleteCandidacy &&
                            <PopupFactory
                                type="delete-offer"
                                itemType="candidacy"
                                itemsLen={1}
                                popupExit={() => { this.setState({ showPopupDeleteCandidacy: false }) }}
                                openShare={this.state.showPopupDeleteCandidacy}
                                actionPopup={this.deleteCandidacy}
                                popupTitle={<FormattedMessage id="delete_offer_popup.candidacy.title.one"/>}
                            />
                        }
                        {this.state.showPopupMessage &&
                            <MessageAllModal
                                popupExit={() => this.closePopupMessage()}
                                items={this.state.checkedItems}
                                openShare={this.state.showPopupMessage}
                            />
                        }

                        <Container maxWidth="lg" >
                            <Box style={{ padding: '12px 0 8px 0' }}>
                                <Button
                                    startIcon={<IconFactory icon='back'/>}
                                    variant='secondary'
                                    size='small'
                                    href={RouteMap.Page('users/company_jobs')}
                                >
                                    <FormattedMessage id="job_results.job_banner.previous" />
                                </Button>
                            </Box>

                            <Grid container spacing={2} style={{ paddingBottom: '16px', display: 'flex', alignItems: 'center' }}>
                                <Grid item xs={12} md={6}>
                                    <Typography
                                        variant='h1'
                                        color='primary'
                                    >
                                        {this.props.job.title}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    {this.props.jobType === 'student_job' && this.props.user.show_headhunt &&
                                        <Box style={{ paddingRight: '12px' }}>
                                            <Button
                                                variant='secondary'
                                                size='small'
                                                href={
                                                    RouteMap.Page(
                                                        'users/headhunt' +
                                                                '?jobId=' +
                                                                this.props.jobId +
                                                                '&jobTitle=' +
                                                                this.props.job.title
                                                    )
                                                }
                                            >
                                                <FormattedMessage id="job_results.job_banner.headhunt" />
                                            </Button>
                                        </Box>
                                    }
                                    <Tooltip title={'Voir job'}>
                                        <IconButton
                                            aria-label="jobShow"
                                            color="primary"
                                            className={classes.iconButton}
                                            target='_blank'
                                            href={RouteMap.Show(this.props.jobType, this.props.url)}
                                        >
                                            <IconFactory icon='view'/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={"Modifier l'annonce"}>
                                        <IconButton
                                            aria-label="jobEdit"
                                            color="primary"
                                            className={classes.iconButton}
                                            target='_blank'
                                            href={RouteMap.Edit(this.props.jobType, this.props.jobId)}
                                        >
                                            <IconFactory icon='edit'/>
                                        </IconButton>
                                    </Tooltip>
                                    <ShareAction
                                        iconButtonClassName={classes.iconButton}
                                        infos={{
                                            title: this.props.job.title,
                                            url: RouteMap.Show(this.props.job.type, this.props.job.url),
                                            type: this.props.job.type,
                                            shareText: 'transl_internship.share_button'
                                        }}
                                        userType={this.props.user.type}
                                    />
                                </Grid>
                            </Grid>
                        </Container>
                        <Container maxWidth="lg" style={{ minHeight: '50vh' }}>
                            <Searchbar
                                type="job_results"
                                overage={this.props.jobType === 'student_job'}
                                searchParams={[]}
                                setFilters={this.setFilters}
                                handleFilterChange={this.handleFilterChange}
                            />

                            <Grid container >
                                {this.props.jobQuestions && this.props.jobQuestions.length > 0 &&
                                    <Grid item xs={12} md={3} className={classes.jobQuestionsContainer}>
                                        <Paper>
                                            <Box p={2}>
                                                <Typography
                                                    variant='h2'
                                                    color='primary'
                                                    style={{ marginBottom: '8px' }}
                                                >
                                                    <FormattedMessage id="job_results.job_questions.title" />
                                                </Typography>
                                                <Typography
                                                    variant="subtitle1"
                                                    style={{ fontStyle: 'italic' }}
                                                >
                                                    <FormattedMessage id="job_results.job_questions.information_text" />
                                                </Typography>
                                            </Box>
                                            <Box p={2}>
                                                {this.renderQuestionsFilter()}
                                            </Box>
                                        </Paper>
                                    </Grid>
                                }
                                <Grid item xs={12} md={this.props.jobQuestions && this.props.jobQuestions.length > 0 ? 9 : 12}>
                                    <Box style={{ marginBottom: '30px', minHeight: 45, display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                            <Hidden mdDown>
                                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                    <CustomCheckbox
                                                        style={{ margin: '0px 8px 0px 8px' }}
                                                        color='primary'
                                                        ref={this.checkAllRef}
                                                        onChange={() => {
                                                            this.checkAll(!this.state.checkedAll)
                                                            this.setState({ checkedAll: !this.state.checkedAll })
                                                        }}
                                                    />
                                                    <Typography
                                                        variant='body2'
                                                        color='primary'
                                                        style={{ fontSize: 14 }}
                                                    >
                                                        {
                                                            Object.keys(this.state.checkedItems).length === this.state.items.length
                                                                ? <FormattedMessage id="job_results.items_actions.deselect_all" />
                                                                : <FormattedMessage id="job_results.items_actions.select_all" />
                                                        }
                                                    </Typography>
                                                </Box>
                                                <Divider orientation='vertical' style={{ height: '25px', margin: '0 16px' }}/>
                                            </Hidden>
                                            <Typography
                                                color='primary'
                                                variant='body2'
                                                style={{ fontSize: 14 }}
                                            >
                                                {this.state.resultCount + ' '}
                                                <FormattedMessage id="job_results.items_actions.candidacies" />
                                            </Typography>
                                            <Box style={{ marginLeft: 20 }}>
                                                {Object.keys(this.state.checkedItems).length > 0
                                                    ? (<>
                                                        <Button
                                                            style={{ fontSize: 12, margin: 4 }}
                                                            variant='primary'
                                                            size='small'
                                                            onClick={() => this.openPopupMessage()}
                                                        >
                                                            <FormattedMessage id="job_results.items_actions.send_message_selection" />
                                                        </Button>
                                                        <Button
                                                            style={{ fontSize: 12, margin: 4 }}
                                                            variant='secondary'
                                                            size='small'
                                                            onClick={() => this.setState({ showPopupDeleteCandidacy: true })}
                                                        >
                                                            <FormattedMessage id="job_results.items_actions.delete_selection" />
                                                        </Button>
                                                    </>)
                                                    : (<Button
                                                        style={{ fontSize: 12, margin: 4 }}
                                                        variant='primary'
                                                        size='small'
                                                        onClick={() => this.downloadExport()}
                                                    >
                                                        <FormattedMessage id="job_results.export_all" />
                                                    </Button>)
                                                }
                                            </Box>
                                        </Box>
                                        <Hidden mdDown>
                                            <SortByDropdown
                                                keys={this.props.sortByKeys}
                                                onClick={this.setSortBy}
                                                sortby={this.state.sortByKey}
                                                order={this.state.orderDirection}
                                            />
                                        </Hidden>
                                    </Box>
                                    {this.state.loading &&
                                        <div className="loading-cirular">
                                            <CircularProgress color='primary' style={{ width: '80px', height: '80px' }}/>
                                        </div>
                                    }
                                    {this.state.loading ||
                                        <>
                                            <ListContainer
                                                ad={false}
                                                listCards={this.state.items}
                                                type={this.props.type}
                                                deleteCard={(studentProfile) => this.setState({ showPopupDeleteCandidacy: studentProfile.candidacy_id })}
                                                jobType={this.props.jobType}
                                                onCheck={this.checkItem}
                                                ref={this.listCardsRef[0]}
                                                idKey={this.props.idKey}
                                                translation={this.t}
                                                noResult={this.state.totalCount}
                                            />
                                            {this.createPageNavigation()}
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                </PageWrapper>
            </>
        )
    }
}

JobResults.defaultProps = {
    jobView: '/',
    jobClics: '/',
    jobCandidats: '/',
    jobFavourites: '/',
    jobType: 'student_job',
    activate: true

}

export default withStyles(useStyles)(JobResults)
