import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    JobShowCompanyImagesContainer: {
        width: '100%',
        padding: '0px 28px',
        [theme.breakpoints.up('md')]: {
            padding: '0px',
            paddingBottom: '93px'
        }
    },
    titleContainer: {
        paddingBottom: '19px',
        display: 'flex',
        columnGap: '8px',
        [theme.breakpoints.up('md')]: {
            paddingBottom: '28px',
            columnGap: '12px'
        }
    },
    iconContainer: {
        width: '28px',
        height: '28px',
        borderRadius: '50%',
        backgroundColor: theme.palette.bg.greyLight,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        color: theme.palette.fg.black,
        fontSize: '18px',
        lineHeight: '28px'
    },

    imagesContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row'
        }
    },
    imageContainer: {
        [theme.breakpoints.up('sm')]: {
            flex: 1
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    image: {
        width: '100%',
        height: '147px',
        objectFit: 'cover',
        border: `1px solid ${theme.palette.fg.silver}`,
        borderRadius: '14px',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '350px'
        }
    }
}))
