/* eslint-disable react/prop-types */
import React from 'react'
import axios from 'axios'

import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import SharedTabs from 'components/SharedTabs/SharedTabs'
import Searchbar from 'components/Searchbar/Searchbar'
import Button from 'components/Button/Button'
import ListContainer from 'containers/ListContainer/ListContainer'
import IconFactory from 'icons/IconFactory/IconFactory'
import SortByDropdown from 'inputs/SortByDropdown/SortByDropdown'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import Divider from '@material-ui/core/Divider'
import debounce from 'lodash.debounce'

import { withStyles } from '@material-ui/core/styles'
import { useStyles } from './HeadHunt_css.jsx'
import { FormattedMessage } from 'react-intl'

class HeadHunt extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            loading: true,
            items: [],
            defaultItems: [],
            itemsSelected: [],
            sortbykey: 'last_online',
            sortbyopp: function (x) { return x },
            order_direction: 'desc',
            checkedItems: {},
            page: 0,
            resultCount: 0,
            studentLeft: 0,
            studentInterested: 0,
            studentNotInterested: 0,
            studentInProcess: 0,
            headhuntsSent: 0,
            headhuntsSelected: 0,
            filters: {
                distance: this.initDistance(),
                legalAge: false,
                drivingLicense: false,
                fieldOfStudies: false,
                status: false,
                langLevel: {
                    fr: false,
                    en: false,
                    nl: false
                }
            }
        }
        this.setSortBy = this.setSortBy.bind(this)
        this.checkItem = this.checkItem.bind(this)
        this.handleFilterChange = this.handleFilterChange.bind(this)
        this.setFilters = this.setFilters.bind(this)
        this.sendHeadHuntInvitation = this.sendHeadHuntInvitation.bind(this)
        this.setPage = this.setPage.bind(this)
        this.createPageBubble = this.createPageBubble.bind(this)
        this.createPageNavigation = this.createPageNavigation.bind(this)
        this.initDistance = this.initDistance.bind(this)
        this.debouncedFilterChange = this.debouncedFilterChange.bind(this)

        axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    }

    initDistance () {
        console.log(this.props)
        if (this.props.jobZipCode >= 1000 && this.props.jobZipCode <= 1300) return 2

        return 4
    }

    setFilters (filters) {
        this.setState({ filters: filters })
    }

    handleFilterChange (filters) {
        this.setState(
            {
                page: 0,
                filters: filters
            }, () => {
                this.filterUser()
            })
    }

    debouncedFilterChange = debounce((filters) => this.handleFilterChange(filters), 300)

    filterUser () {
        this.setState({ loading: true }, () => {
            axios({
                url: '/api/v1/users/headhunt_filter',
                method: 'post',
                data: {
                    student_job_id: this.props.jobId,
                    filters: {
                        page: this.state.page,
                        study_domain: this.state.filters.fieldOfStudies,
                        distance_to_job: this.state.filters.distance,
                        eighteen_plus: this.state.filters.legalAge,
                        driving_licence: this.state.filters.drivingLicense,
                        languages: this.state.filters.langLevel,
                        status: this.state.filters.status
                    },
                    order_by: this.state.sortbykey,
                    order_direction: this.state.order_direction
                }
            }).then(response => {
                console.log(response.data)
                const responseItems = []
                if (!response.data.none_found) {
                    responseItems: response.data.user_list
                }
                this.setState({
                    loading: false,
                    items: response.data.user_list,
                    defaultItems: response.data.user_list,
                    resultCount: response.data.result_count,
                    studentLeft: response.data.student_left,
                    studentInterested: response.data.student_interested,
                    studentNotInterested: response.data.student_not_interested,
                    studentInProcess: response.data.student_waiting,
                    headhuntsSent: response.data.headhunts_sent,
                    headhuntsSelected: 0
                })
            }).catch((error) => {
                console.log(error)
            })
        })
    }

    sendHeadHuntInvitation () {
        console.log('Sending invitation process')
        const { checkedItems } = this.state
        const { jobId } = this.props
        console.log(checkedItems)
        axios({
            url: this.props.invitationUrl,
            method: 'post',
            params: {
                students_invitation: Object.keys(checkedItems),
                job_id: jobId
            }
        }).then(response => {
            window.location.reload()
        })
    }

    setSortBy (key) {
        if (this.state.sortbykey !== key) {
            this.setState(
                {
                    sortbykey: key,
                    sortbyopp: function (x) { return x },
                    order_direction: 'asc'
                }, () => {
                    this.filterUser()
                })
        } else if (this.state.sortbyopp(1) === 1) {
            this.setState(
                {
                    sortbyopp: function (x) { return -x },
                    order_direction: 'desc'
                }, () => {
                    this.filterUser()
                })
        } else {
            this.setState(
                {
                    sortbykey: '',
                    sortbyopp: function (x) { return x },
                    order_direction: 'asc'
                }, () => {
                    this.filterUser()
                })
        }
    }

    checkItem (ref, item) {
        if ((this.state.headhuntsSelected + this.state.headhuntsSent) > 50) {

        } else {
            const newCheckedItems = this.state.checkedItems
            let counter = 0
            if (item.id in newCheckedItems) {
                counter = -1
                delete newCheckedItems[item.id]
                // this.changeCheckAll()
            } else {
                newCheckedItems[item.id] = item
                counter = 1
            }
            this.setState({
                checkedItems: newCheckedItems,
                headhuntsSelected: this.state.headhuntsSelected + counter
            })
        }
    }

    renderHeadHuntData () {
        const { classes } = this.props

        return (
            <Box className={classes.dataContainer}>
                <Box className={classes.dataElemContainer}>
                    <Typography
                        variant='h1'
                        component='h3'
                        color='primary'
                        className={classes.dataTitle}
                    >
                        {this.state.studentInterested}
                    </Typography>
                    <Typography
                        variant='subtitle1'
                        className={classes.dataText}
                    >
                        <FormattedMessage id="headhunt.status.interested" />
                    </Typography>
                </Box>
                <Box className={classes.dataElemContainer}>
                    <Typography
                        variant='h1'
                        component='h3'
                        color='primary'
                        className={classes.dataTitle}
                    >
                        {this.state.studentNotInterested}
                    </Typography>
                    <Typography
                        variant='subtitle1'
                        className={classes.dataText}
                    >
                        <FormattedMessage id="headhunt.status.not_interested" />
                    </Typography>
                </Box>
                <Box className={classes.dataElemContainer}>
                    <Typography
                        variant='h1'
                        component='h3'
                        color='primary'
                        className={classes.dataTitle}
                    >
                        {this.state.studentInProcess}
                    </Typography>
                    <Typography
                        variant='subtitle1'
                        className={classes.dataText}
                    >
                        <FormattedMessage id="headhunt.status.waiting" />
                    </Typography>
                </Box>
            </Box>
        )
    }

    setPage (page) {
        if (page > -1 && page > -1) {
            this.setState({
                page: page
            }, () => {
                this.filterUser()
                window.scrollTo(0, 0)
            })
        }
    }

    createPageBubble () {
        const { classes } = this.props
        const res = []
        let offset = 1
        const pagesNumber = Math.ceil(this.state.resultCount / 20 - 1)
        if (this.state.page !== 0) {
            offset -= 1
        }
        if (this.state.page === pagesNumber && pagesNumber > 1) {
            offset -= 1
        }
        for (let i = 0; i < Math.min(3, pagesNumber + 1); i++) {
            const page = this.state.page + offset + i
            var addStyle = (i + offset === 1) ? classes.active : classes.nonActive
            var style = `${classes.pageBubble} ${addStyle}`
            res.push(
                <Typography
                    variant='body1'
                    component='div'
                    className={style}
                    key={'page-' + page}
                    onClick={() => this.setPage(page - 1)}
                >
                    {page}
                </Typography>
            )
        }
        return res
    }

    createPageNavigation () {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', padding: '20px 0' }}>
                {this.createPageBubble()}
            </Box>
        )
    }

    render () {
        const { classes } = this.props

        return (
            <div className="head-hunt-container student-job">
                <PageWrapper user={this.props.user} disableFooterLists>
                    <SharedTabs user={this.props.user} currentTab='users/company_jobs' />
                    <Box pb={4} style={{ marginBottom: '-8px' }}>
                        <Container maxWidth='lg'>
                            <Box className={classes.linksContainer}>
                                <Button
                                    startIcon={<IconFactory icon='back'/>}
                                    variant='secondary'
                                    size='small'
                                    href={this.props.redirectionOffersUrl}
                                >
                                    <FormattedMessage id="headhunt.banner.previous" />
                                </Button>

                                <Button
                                    endIcon={<IconFactory icon='forward'/>}
                                    variant='secondary'
                                    size='small'
                                    href={this.props.jobResultsUrl + '?jobId=' + this.props.jobId + '&jobType=student_job'}
                                >
                                    <FormattedMessage id="headhunt.banner.go_to_results" />
                                </Button>
                            </Box>

                            <Box className={classes.titlePageContainer}>
                                <Typography
                                    variant='h1'
                                    style={{ marginBottom: 16 }}
                                >
                                    <FormattedMessage id="headhunt.banner.title" />
                                </Typography>
                                <Typography
                                    variant='subtitle1'
                                    style={{ fontStyle: 'italic' }}
                                >
                                    <FormattedMessage id="headhunt.banner.information_text" />
                                </Typography>
                            </Box>

                            <Hidden mdDown>
                                {this.renderHeadHuntData()}
                            </Hidden>
                        </Container>
                    </Box>
                    <Container maxWidth='lg'>
                        <Searchbar
                            type='student_headhunt'
                            searchParams={this.props.searchParams}
                            handleFilterChange={this.debouncedFilterChange}
                            setFilters={this.setFilters}
                            filters={this.state.filters}
                        />
                        <Box className={classes.studentInfosContainer}>
                            <Grid container spacing={1}>
                                <Grid container item xs={12} md={6}>
                                    <Box className={classes.checkItemsContainer} justifyContent={'flex-start'}>
                                        <Typography
                                            color='primary'
                                            variant='body2'
                                            style={{ fontSize: 14 }}
                                        >
                                            {this.state.resultCount + ' '}
                                            <FormattedMessage id="headhunt.items_actions.matching_student" />
                                        </Typography>
                                        <Divider orientation='vertical' style={{ height: '25px', margin: '0 16px' }}/>
                                        <Typography
                                            color='primary'
                                            variant='body2'
                                            style={{ fontSize: 14 }}
                                        >
                                            {`${this.state.headhuntsSent}/50 `}
                                            <FormattedMessage id="headhunt.sent" />
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid container item xs={12} md={6}>
                                    <Box className={classes.sendItemsContainer}>
                                        <Typography
                                            color='primary'
                                            variant='body2'
                                            style={{ fontSize: 14 }}
                                        >
                                            {`${this.state.headhuntsSelected}/${50 - this.state.headhuntsSent} `}

                                            <FormattedMessage id="headhunt.items_actions.selected_student" />
                                        </Typography>
                                        <Button
                                            onClick={() => this.sendHeadHuntInvitation()}
                                            variant='secondary'
                                            size='small'
                                            className={classes.sendButton}
                                        >
                                            <FormattedMessage id="headhunt.items_actions.send" />
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mb={3} display='flex' justifyContent='flex-end'>
                            <SortByDropdown
                                type={'student_job'}
                                keys={[
                                    [<FormattedMessage id="headhunt.sort_by.last_connection" />, 'last_online'],
                                    [<FormattedMessage id="headhunt.sort_by.age" />, 'age'],
                                ]}
                                onClick={this.setSortBy}
                                sortby={this.state.sortbykey}
                                order={this.state.sortbyopp(1)}
                            />
                        </Box>

                        {this.state.loading &&
                                <div className="loadingState studentJob">
                                    <div className="lds-ring">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                        }
                        {this.state.loading ||
                                <>
                                    <ListContainer
                                        key='listcontainer_99'
                                        type='student_headhunt'
                                        displayShow={null}
                                        listCards={this.state.items}
                                        ad={false}
                                        onCheck={this.checkItem}
                                        idKey={'student_id'}
                                        translation={this.t}
                                    />
                                    {this.state.resultCount > 20 &&
                                        this.createPageNavigation()
                                    }
                                </>
                        }

                    </Container>
                </PageWrapper>
            </div>
        )
    }
}

HeadHunt.defaultProps = {
    studentNbrDB: 210000,
    studentMatch: 9,
    studentLeft: 10,
    studentInterested: 10,
    studentNotInterested: 10,
    studentInProcess: 20
}

export default withStyles(useStyles)(HeadHunt)
