import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    bannerContainer: {
        width: '100%',
        maxWidth: '1440px',
        paddingTop: '38px',
        backgroundColor: theme.palette.bg.purple,
        backgroundImage: 'url(\'https://res.cloudinary.com/studentbe/image/upload/v1721138136/student-be-assets/Mask_group.png\')',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between'
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: '0px'
        },
        [theme.breakpoints.up(1440)]: {
            borderRadius: 14
        }
    },
    upContainer: {
        width: '100%',
        padding: '0px 20px 5px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            flex: 1,
            paddingRight: '0px',
            paddingBottom: '54px',
            alignItems: 'flex-start'
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: '80px'
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '950px'
        }
    },
    titleContainer: {
        paddingBottom: '16px',
        color: theme.palette.fg.white,
        fontSize: '75px',
        lineHeight: '77px',
        textTransform: 'uppercase',
        textAlign: 'left',
        [theme.breakpoints.down('md')]: {
            fontSize: '51px',
            lineHeight: '53px'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '40px',
            lineHeight: '50px',
            textAlign: 'center'
        },
        '& .GreedWideLight': {
            fontFamily: 'GreedWide-Light',
            fontWeight: 300
        },
        '& .GreedWideSemiBold': {
            fontFamily: 'GreedWide-SemiBold',
            fontWeight: 300
        },
        '& .GreedCondensedHeavy': {
            fontFamily: 'GreedCondensed-Heavy',
            fontWeight: 900
        },
        '& .GreedCondensedRegular': {
            fontFamily: 'GreedCondensed-Regular',
            fontWeight: 400
        },
        '& .GreedCondensedLight': {
            fontFamily: 'GreedCondensed-Light',
            fontWeight: 400
        },
        '& .GreedNarrowHeavy': {
            fontFamily: 'GreedNarrow-Heavy',
            fontWeight: 900
        }
    },
    description: {
        fontSize: 20,
        maxWidth: '380px',
        padding: '0px 20px 32px 20px',
        color: theme.palette.fg.white,
        textAlign: 'center',
        lineHeight: '18px',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'left',
            padding: '0px 0px 32px'
        }
    },
    downContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            width: '50%',
            justifyContent: 'flex-end'
        },
        [theme.breakpoints.up('lg')]: {
            width: 'fit-content'
        }
    },
    image: {
        width: '100%',
        height: '404px',
        objectFit: 'cover',
        [theme.breakpoints.up('md')]: {
            height: '520px',
            maxWidth: '603px'
        },
        [theme.breakpoints.up(1440)]: {
            borderRadius: 14
        }
    }
}))
